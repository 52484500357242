import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';

export type CaptchaProps = {
    image: string;
    cellColor1: string;
    cellColor2: string;
    onChange: (answer: string) => void;
} & PropsWithChildren<{}>

const s = 40, // cell size
    pad = 2;

const clamp = x => Math.min(Math.max((x / s) | 0 , 0), 8);

export const Captcha = (props: CaptchaProps) => {

    const [cells, setCells] = useState<number[]>([])
    const gRef = useRef<SVGSVGElement>();
    const svgPointRef = useRef<SVGPoint>();

    useEffect(() => {
        setCells([])
        gRef.current.innerHTML = props.image
    }, [props.image])

    function click(e) {
        let svg  = gRef.current.parentNode as SVGSVGElement;
        if (!svgPointRef.current)
            svgPointRef.current = svg.createSVGPoint();
        svgPointRef.current.x = e.clientX;
        svgPointRef.current.y = e.clientY;
        const pt = svgPointRef.current.matrixTransform(
            svg.getScreenCTM().inverse()
        );
        if (pt.y < 0)
            return;

        const index = 8 * clamp(pt.y) + clamp(pt.x);

        const newValue = cells.includes(index) ?
            cells.filter(c => c !== index) :
            [...cells, index];

        setCells(newValue);

        const answer = newValue.sort((a,b)=>a-b).join('');

        props.onChange(answer);
    }

    return (
        <svg
            viewBox={'' + [0, -s, s*8, s*9]}
            onClick={click}
        >
            <g
                ref={gRef}
                transform={`translate(0,${-s})`}
            />
            <g
                fill={'none'}
                stroke={'#4496f0'}
                strokeWidth={2}
            >
                {cells.map(i => <rect
                    key={i}
                    width={s - pad * 2}
                    height={s - pad * 2}
                    x={pad + s * (i%8)}
                    y={pad + s * ((i/8)|0) }
                />)}
            </g>
        </svg>
    );
};


