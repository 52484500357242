import React from 'react';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import styled from 'styled-components';
import { MovesChain } from '../../../components/MovesPanel/MovesChain';
import { TaskEditViewButton } from './TaskEditViewButton';
import { TaskType } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';
import { JustText } from '../../../components/JustText/JustText';
import { useTasksStore } from '../../../store/tasks/TasksStore';
import { useLocalization } from '../../../l10n/UseLocalization';


const StyledMoves = styled.div`
  //max-width: 300px;
`;

const Btn = styled.div`
  font: var(--font-normal);
  padding: 5px;
  border-radius: 5px;
`;

export function TaskEditMovesTable() {
  const { editingTask } = useEditingTaskStore();
  const {lesson} = useLocalization();

  if (editingTask.type === TaskType.GAME)
    return null;
  // debugger
  return <StyledMoves>
    <div style={{ display: 'flex', gap: 3 }}>

    </div>
    {editingTask.moves?.length ?
      <MovesChain moves={editingTask.fullMoveDescriptions} /> :
      <JustText style={{ padding: 10 }}>{lesson.empty}</JustText>
    }
  </StyledMoves>;
}