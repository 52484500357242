import React, { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';
import styled from 'styled-components';
import { useThemeStore } from '../../store/themeStore';

const StyledScrollbarWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden ;
`;

const StyledHidingScrollbar = styled.div`
  overflow: hidden auto;
  z-index: 1;
  box-sizing: border-box;
  //overscroll-behavior: contain;
  --bg-col: ${({ theme }) => theme.currentTheme.background};
  --bg-col-transparent: ${({ theme }) => theme.currentTheme.background}00;

  &.before-visible {
    mask-image: linear-gradient(to top, #000 calc(100% - 20px), transparent 100%);
  }
  &.after-visible {
    mask-image: linear-gradient(to top, transparent, #000 20px);
  }
  &.before-visible.after-visible {
    mask-image: linear-gradient(to top, transparent, #000 20px, #000 calc(100% - 20px), transparent 100%);
  }

  .to-top {
    position: absolute;
    bottom: 0;
    right: calc(50% - 35px);
    background-color: rgb(190 190 190 / 20%);
    border-radius: 3px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 1001;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(147, 147, 147 , 0)!important;
    transition: 0.3s;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(147, 147, 147 , 1)!important;
  }
`;


interface IProps extends PropsWithChildren<{}>{
  style?: CSSProperties;
  elementRef?: React.MutableRefObject<HTMLDivElement>;
  iconStyle?: CSSProperties;
  onIconClick?: () => void;
}

export function HidingScrollbar(props: IProps) {
  let {
    children,
    style,
    iconStyle,
    onIconClick,
    elementRef
  } = props;

  let ref = useRef<HTMLDivElement>();
  if (elementRef)
    ref = elementRef;

  const {theme} = useThemeStore();

  // const [scrollbarOpacity, setScrollbarOpacity] = useState(1);
  // const [shouldShowScrollToTopIcon, setShouldShowScrollToTopIcon] = useState(false);
  // const intervalId = useRef<NodeJS.Timeout>();
  // const timeoutId = useRef<NodeJS.Timeout>();
  // const styledRef = useRef<HTMLDivElement>();
  // const cmpRef = ref || styledRef;
  // Handlers
  // const mouseEnterHandler = () => {
  //   clearTimeout(timeoutId.current);
  //   clearInterval(intervalId.current);
  //   timeoutId.current = setTimeout(mouseLeaveHandler, 2000);
  //   intervalId.current = setInterval(() => {
  //     setScrollbarOpacity((prev) => {
  //       if (prev === 1) {
  //         clearInterval(intervalId.current);
  //         return 1;
  //       } else {
  //         return parseFloat((prev + 0.1).toFixed(2));
  //       }
  //     });
  //   }, 10);
  // };
  // const mouseLeaveHandler = () => {
  //   clearInterval(intervalId.current);
  //   intervalId.current = setInterval(() => {
  //     setScrollbarOpacity((prev) => {
  //       if (prev === 0) {
  //         clearInterval(intervalId.current);
  //         return 0;
  //       } else {
  //         return parseFloat((prev - 0.1).toFixed(2));
  //       }
  //     });
  //   }, 10);
  // };
  // const handleViewToTop = () => {
  //   cmpRef.current.scrollTo(0, 0);
  // };
  //
  // useEffect(
  //   () => () => {
  //     clearTimeout(timeoutId.current);
  //     clearInterval(intervalId.current);
  //   },
  //   [],
  // );
  // const ScrollToTopElement =
  //   shouldShowScrollToTopIcon && scrollbarOpacity !== 0 ? (
  //     <SvgImage
  //       style={{ opacity: scrollbarOpacity, ...iconStyle }}
  //       onClick={onIconClick ? onIconClick : handleViewToTop}
  //       className='to-top'
  //       icon={KnownIcons.arrow_right}
  //     />
  //   ) : null;

  let onScroll = () => {
    // if (
    //   toBottom
    //     ? cmpRef.current.scrollHeight - cmpRef.current.offsetHeight === Math.round(cmpRef.current.scrollTop) ||
    //     cmpRef.current.scrollHeight === 0 ||
    //     cmpRef.current.scrollHeight - cmpRef.current.offsetHeight === Math.round(cmpRef.current.scrollTop) - 1 ||
    //     cmpRef.current.scrollHeight - cmpRef.current.offsetHeight === Math.round(cmpRef.current.scrollTop) + 1
    //     : cmpRef.current.scrollTop === 0
    // ) {
    //   setShouldShowScrollToTopIcon(false);
    // } else {
    //   setShouldShowScrollToTopIcon(true);
    // }

    ref.current.classList.toggle("before-visible",
      ref.current.scrollTop > 2)

    ref.current.classList.toggle("after-visible",
      ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop > 2)
  };

  useEffect(() => {
    onScroll() // crutch: apply mask after theme change, not sure why it disappears
  }, [theme]);

  return <StyledScrollbarWrapper>
    <StyledHidingScrollbar
      onScroll={onScroll}
      ref={ref}
      style={style}
      // onMouseLeave={mouseLeaveHandler}
      // onMouseOver={mouseEnterHandler}
    >
      {/*{ScrollToTopElement}*/}
      {children}
    </StyledHidingScrollbar>
  </StyledScrollbarWrapper>;
}

