import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from '../../components/low-level-components/Link';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { StyledButton } from '../RegisterPage/RegisterSteps';
import { LoginPageStyledComponents } from './LoginPageStyledComponents';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useStoredState } from '../../hooks/useStoredState';
import { SocialAuth } from './SocialAuth';
import { useLocalization } from '../../l10n/UseLocalization';
import { Space } from '../../components/low-level-components/Space';
import { EmailField } from '../../components/EmailField/EmailField';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';

const {
  LinkWrapper, ButtonWrapper, InputWrapper,
} = LoginPageStyledComponents;

export interface ILoginForm {
  email: string;
  password: string;
}


export function LoginPage() {

  const { authError, passwordAuth } = useAuthStore();

  const { auth } = useLocalization();
  const [email, setEmail] = useStoredState<string | undefined>('register-email', '');
  const [formData, setFormData] = useState<ILoginForm>({ email, password: '' });
  const [notValid, setNotValid] = useState<boolean>(false);

  let errorText = authError?.text;
  if (auth.errors[errorText]) {
    errorText = auth.errors[errorText];
  }
  return <PageTemplate header={false}>
    <LoginPageContainer>
          <Space size={50} />
          <SocialAuth />
            <Space size={10}/>
            <InputWrapper>
              <EmailField
                errorText={errorText}
                email={formData.email}
                notValid={notValid}
                setNotValid={setNotValid}
                setEmail={(email) => {
                  setFormData((prev) => ({ ...prev, email }));
                  setEmail(email);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <PasswordInput
                rows={1}
                label={auth.password}
                fullWidth
                value={formData.password}
                onChange={(e) => {
                  setFormData((prev) => ({ ...prev, password: e.target.value }));
                }}
                error={authError?.password}
              />
            </InputWrapper>
            <ButtonWrapper>
              <StyledButton
                fullWidth
                disabled={!formData.email || !formData.password || notValid}
                onClick={() => passwordAuth(formData.email, formData.password)}>
                {auth.login}
              </StyledButton>
            </ButtonWrapper>
          <Grid container justifyContent='center' direction='row' style={{ paddingTop: 15 }}>
            <LinkWrapper>
              <Link to={`/auth/recover/` + document.location.pathname.split("/").pop() + `${formData.email}`}>{auth.forgot_password}</Link>
            </LinkWrapper>
            <div style={{ margin: '0 15px' }}>    </div>
            <LinkWrapper>
              <Link to={"/auth/register/" + document.location.pathname.split("/").pop()}>{auth.sign_up}</Link>
            </LinkWrapper>
          </Grid>
    </LoginPageContainer>
  </PageTemplate>;

}
