import styled from 'styled-components';
import React, { PropsWithChildren } from 'react';

export const TaskEditContainer = styled.div`
  height: 100%;
  display: grid;
  grid-column-gap: 10px;
  //padding-top: var(--top-gap);
  grid-row-gap: 0;
  grid-template-columns: 2fr 500px 1fr 50vw;
  //grid-template-rows: 0fr auto;
  grid-template-areas:   
          'H H H H'
          'Z L X R' 
          'B B B B' 
     
;

  & > * {
    gap: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
