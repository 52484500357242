let host = document.location.hostname;

if (document.location.port === "3000")
  host = 'dev.chessclub.spb.ru';

export const centrifugeUrl = `wss://${host}/centrifugo/connection/websocket`;

export const grpcUrl = `https://${host}/app`;

export const ajaxUrl = `https://${host}`;

export const rtcConfig: RTCConfiguration = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302'
    },
    {
      urls: 'stun:81.200.147.103:3478'
    }, // chessclub
    {
      urls: 'turn:global.relay.metered.ca:80',
      username: 'f6507426c0f4f89d0bda02e2',
      credential: '7YF0907XexAfvkbL',
    },
    {
      urls: 'turn:global.relay.metered.ca:443',
      username: 'f6507426c0f4f89d0bda02e2',
      credential: '7YF0907XexAfvkbL',
    },
    {
      urls: 'turn:81.200.147.103:3478',
      username: 'chessclubturn',
      credential: 'vhSsAm602ITECZv1',
    }, // chessclub
  ],
};


export const chessModel = 'https://s3.timeweb.cloud/2cfb1228-a803a284-05da-4599-a2d5-2b668238e2e1/chess.glb'