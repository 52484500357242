import { CameraCardBase } from '../components/CameraCardBase';
import React from 'react';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { CameraCardAvatar } from '../components/CameraCardAvatar';

export function InactiveRemoteCameraCard({ user, canMove }: {
  user: ChessclubUser;
  canMove: boolean;
}) {

  return <CameraCardBase
    key={user.socketId}
    user={user}
    canMove={canMove}
    showDisabledMedia={true}
    cameraEnabled={false}
    localCameraEnabled={true}
    microEnabled={false}
    localMicroEnabled={true}
    toggleCameraEnabled={null}
    toggleMicroEnabled={null}
  >
    <CameraCardAvatar user={user}/>
  </CameraCardBase>
}