import { ConfirmationPopupMenu } from '../PopupMenu/ConfirmationPopupMenu';
import { PropsWithChildren } from 'react';
import { useLessonStore } from '../../store/lesson/LessonStore';
import { useRoomsStore } from '../../store/rooms/RoomsStore';
import React from 'react';
import { RoomId } from '@chessclub/grpc_wrapper';
import { useTasksStore } from '../../store/tasks/TasksStore';
import { wgsStartTask, wgsStopTask } from '../../app/workers';
import { BoardLabels } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';

export function OnlineButton(props: PropsWithChildren<{ roomId: RoomId }>) {

  const { items, goOffline: offRoom, goOnline: onRoom } = useRoomsStore();
  const { goOffline: offLesson, goOnline: onLesson,  } = useLessonStore();
  const {activeTask, currentTaskGameState} = useTasksStore();

  const room = items.find(room => room.id === props.roomId);

  const toggle = async (e) => {

    e && e.stopPropagation()
    e && e.preventDefault()

    if (room.isActive) {
      await offRoom(props.roomId);
      await offLesson(props.roomId);
    } else {

        wgsStopTask(activeTask.id)
      let task = {
        ...activeTask,
        fen: currentTaskGameState.fen,
        tools: currentTaskGameState.markers,
        moves: currentTaskGameState.moves,
        moveIndex: currentTaskGameState.moveIndex,
        customData: {
          boardLabels: currentTaskGameState.boardLabels,
          rulesSettingOn: currentTaskGameState.applyRules,
          highlightMovesSettingOn: currentTaskGameState.legalMoves,
          timerSettingOn: currentTaskGameState.isTimerOn
        }
      };
      wgsStartTask(task, room.userId, room.userId);

      await onRoom(props.roomId);
      await onLesson(props.roomId);
    }
  };

  if (!room?.isActive || !room?.anyStudentPresents) {
    return <div onClick={toggle}>
      {props.children}
    </div>
  }

  return <ConfirmationPopupMenu
    text={'Выйти?'}
    action={toggle}
  >
    {props.children}
  </ConfirmationPopupMenu>;
}

