const imagesPath = 'https://chessclub.spb.ru/landing/img/';

export const LandingPageImages = {
  photoBoardMain: imagesPath + 'main.png',
  photoGirl: imagesPath + 'girl.png',
  photoFiguresBlack: imagesPath + 'figures_black.png',
  photoFiguresWhite: imagesPath + 'figures_white.png',
  photoPawnOnBoard: imagesPath + 'pawn_on_board.png',
  photoHandBlack: imagesPath + 'hand_black.png',
  photoHandWhite: imagesPath + 'hand_white.png',
  handFiguresBlack: imagesPath + 'hand_figures_black.png',
  handFiguresWhite: imagesPath + 'hand_figures_white.png',

};