import React from 'react';
import { ZeroSizeCenteredContainer } from './ZeroSizeCenteredContainer';
import { LandingPageImages } from './LandingPageImages';
import { Theme, useThemeStore } from '../../store/themeStore';

export function LandingPageBgPart1(props: {y?: number, z?: number}) {
  return <ZeroSizeCenteredContainer>
    <svg width="175" height="100" viewBox="550 -100 175 100"
      overflow="visible" style={{ zIndex: -1 }}>
      <image href={LandingPageImages.photoBoardMain} />
    </svg>
  </ZeroSizeCenteredContainer>
}