import Input from '../../components/low-level-components/Input';
import React, { useEffect, useState } from 'react';
import { ButtonWrapper, InputWrapper, StyledButton } from './RegisterSteps';
import { Grid } from '@material-ui/core';
import { TextLink } from '../../components/low-level-components/TextLink';
import styled, { useTheme } from 'styled-components';
import { RegisterStep } from '../../store/auth/AuthStoreApi';
import { ServerApi } from '../../transport/ServerApi';
import { Lang, useUiStore } from '../../store/uiStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Lang as GrpcLang, Role } from '@chessclub/grpc_wrapper';
import { EmailField } from '../../components/EmailField/EmailField';
import { validateEmail } from '../../logic/validateEmail';

export const StyledTitle = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
  color: #bebebe;
  text-align: center;
  font: ${({ theme }) => theme.fonts.normal};
`;

type EmailPhaseProps = {
  email: string;
  setEmail(email: string): void;
  setPhase(phase: RegisterStep): void;
};

export const EmailStep = (props: EmailPhaseProps) => {
  const { auth } = useLocalization();
  const { lang } = useUiStore();
  const [error, setError] = useState('');
  const [notValid, setNotValid] = useState<boolean>(false)

  useEffect(() => {
    setNotValid(!validateEmail(props.email))
  }, [props.email])

  async function requestCaptchaIsNeeded() {
    const response = await ServerApi.captchaService.emailIsExpired(props.email);
    props.setPhase(response ? RegisterStep.CAPTCHA : RegisterStep.COMPLETE);
  }

  async function registerAttempt() {
    setError('');
    const role = document.location.href.endsWith('register') ? Role.TEACHER : Role.STUDENT;
    const result = await ServerApi.accessService.registration(props.email, lang, role);
    result ? setError(result) : await requestCaptchaIsNeeded();
  }
  // console.log('notValid', notValid)
  return (
    <>
      <StyledTitle>{auth.sign_up}</StyledTitle>
      <InputWrapper>
        <EmailField
          errorText={auth.errors[error]}
          notValid={notValid}
          setNotValid={setNotValid}
          email={props.email}
          onEnterPressed={registerAttempt}
          setEmail={(email) => {
            props.setEmail( email)
          }}
        />
      </InputWrapper>
      <ButtonWrapper>
        <StyledButton fullWidth onClick={registerAttempt} disabled={!props.email || notValid}>
          {auth.receive_code_in_mail}
        </StyledButton>
      </ButtonWrapper>
      <Grid container justifyContent='center' direction='column'>
        <TextLink linkText={auth.login} to='/auth/login' />
      </Grid>
    </>
  );
};
