import React from 'react';
import styled from 'styled-components';
import { FENEnum } from '../../../interfaces/question';
import { FenButton } from './FenButtons/FenButton';
import { RecognizeButton } from './FenButtons/RecognizeButton';

const StyledFenButtonsContainer = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 600px;
`;

export function TaskEditFenButtons() {
  return <StyledFenButtonsContainer>
    <FenButton fen={FENEnum.empty} />
    <FenButton fen={FENEnum.initial} />
    <RecognizeButton />
  </StyledFenButtonsContainer>;
}


