import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Input from '../../components/low-level-components/Input';
import { CaptchaStep } from '../../components/Captcha/CapthcaStep';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { StyledButton } from '../RegisterPage/RegisterSteps';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useParams } from 'react-router-dom';
import { useStoredState } from '../../hooks/useStoredState';
import { ServerApi } from '../../transport/ServerApi';
import { loggedUserFromGrpcUser } from '../../logic/loggedUserFromGrpcUser';
import { useLocalization } from '../../l10n/UseLocalization';
import { useUiStore } from '../../store/uiStore';
import { TextLink } from '../../components/low-level-components/TextLink';
import { EmailField } from '../../components/EmailField/EmailField';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';
import styled from 'styled-components';

export interface IRecoverForm {
  email: string;
  password: string;
  passwordRepeat: string;
  code: string;
}

export interface IRecoverFormErrors {
  email?: boolean;
  password?: boolean;
  passwordRepeat?: boolean;
  code?: boolean;
}

const StyledMessage = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: #bebebe;
  padding: 0px;
  margin: 40px 0px 28px 0px;
  text-align: center;
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  width: 100%;
`;

export function RecoverPage() {
  const lang = useLocalization();
  const { user, setUser } = useAuthStore();
  const {lang: siteLang} = useUiStore();
  const { email } = useParams<{ email: string }>();
  const [notValid, setNotValid] = useState<boolean>(false)
  const initialFormData: IRecoverForm = { email, password: '', passwordRepeat: '', code: '' };
  const initialErrors: IRecoverFormErrors = { email: false, password: false };
  const [_, setEmail] = useStoredState<string | undefined>('register-email', '');

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const [step, setStep] = useState<'initial' | 'enter-password' | 'captcha'>('initial');
  const [confirmError, setConfirmError] = useState('');
  const { auth } = useLocalization();
  let isButtonActionDisabled: boolean;

  switch (step) {
    case 'initial':
      isButtonActionDisabled = !formData.email || notValid;
      break;
    case 'enter-password':
      isButtonActionDisabled = !formData.password || !formData.passwordRepeat || !formData.code;
      break;
  }

  const handleClick = async () => {
    let errors: IRecoverFormErrors = {};

    switch (step) {
      case 'initial':
        if (!formData.email) errors.email = true;
        if (!Object.values(errors).length) {
          const result = await ServerApi.accessService.restorePassword(formData.email, siteLang);
          if( typeof result === 'string') {
            setConfirmError(result);
            setStep('initial');
          } else {
            setConfirmError('');
            setStep('captcha');
          }
        }
        break;

      default:
        if (!formData.password) errors.password = true;
        if (!formData.passwordRepeat) errors.passwordRepeat = true;
        if (formData.password !== formData.passwordRepeat) {
          errors.password = true;
          errors.passwordRepeat = true;
        }
        if (!formData.code) errors.code = true;
        if (!Object.values(errors).length) {
          const user = await ServerApi.accessService.completeRestorePassword(
            formData.email,
            formData.password,
            formData.code
          )

          if (typeof user === "string") {
            setConfirmError(user)
          } else {
            setUser(loggedUserFromGrpcUser(user));
          }

          setStep('enter-password');
        }
        break;
    }

    setErrors(errors);
  };

  return <PageTemplate header={false}>
    <LoginPageContainer>
      <Grid container justifyContent="center" direction="column">
        {step === 'initial' && (
          <StyledMessage>
            {lang.auth.email_to_restore}
          </StyledMessage>
        )}
        {step === 'captcha' && (
          <CaptchaStep
            email={formData.email}
            setPhase={() => setStep('enter-password')}
            confirmButtonText={lang.auth.continue}
            onSuccess={() => setStep('enter-password')}
          />
        )}
        {step === 'enter-password' && (
          <StyledMessage>
            {lang.auth.consider_new_password + ' ' + formData.email}
          </StyledMessage>
        )}
      </Grid>
      <Grid container justifyContent="center" direction="column">
        {step === 'initial' && (
          <InputWrapper>
            <EmailField
              errorText={auth.errors[confirmError] }
              email={formData.email}
              notValid={notValid}
              setNotValid={setNotValid}
              setEmail={(email) => {
                setFormData((prev) => ({ ...prev, email }));
                setEmail(email);
              }}
            />
          </InputWrapper>
        )}
        {step === 'enter-password' && (
          <>
            <InputWrapper>
              <PasswordInput
                label={lang.auth.new_password}
                fullWidth
                value={formData.password}
                onChange={(e: any) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
                error={errors.password}
              />
            </InputWrapper>
            <InputWrapper>
              <PasswordInput
                label={lang.auth.repeat_new_password}
                fullWidth
                value={formData.passwordRepeat}
                onChange={(e: any) => setFormData((prev) => ({ ...prev, passwordRepeat: e.target.value }))}
                error={errors.passwordRepeat}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label={lang.auth.code}
                fullWidth
                value={formData.code}
                onChange={(e: any) => setFormData((prev) => ({ ...prev, code: e.target.value }))}
                error={errors.code}
              />
            </InputWrapper>
          </>
        )}

        {step !== 'captcha' && (
          <ButtonWrapper>
            <StyledButton disabled={isButtonActionDisabled} fullWidth onClick={handleClick}>
              {step === 'initial' && lang.auth.continue}
              {step === 'enter-password' && lang.auth.confirm}
            </StyledButton>
          </ButtonWrapper>
        )}
      </Grid>
      <Grid container justifyContent="center" direction="column">
        {step !== "captcha"&&<TextLink linkText={lang.auth.login} to={'/auth/login'} />}
      </Grid>
  </LoginPageContainer>
</PageTemplate>

}
