import React from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import styled from 'styled-components';
import { useLocalization } from '../../l10n/UseLocalization';
import { LargeText } from '../../components';


const TextBlock = styled.div`
  font-family: Roboto;
  font-size: 18px;
  padding: 20px;
  width: 1000px;
  margin: 0 auto;
  color: ${p => p.theme.currentTheme.iconMain};
`

export function TermsOfService() {
  const {termsOfService: {parts, header}} = useLocalization();
  return <PageTemplate>
    <LargeText text={header}/>
    {parts.map((text, i) => {
      return <TextBlock key={i}>{text}</TextBlock>
    })}
  </PageTemplate>;
}