import React, { useState } from 'react';
import { Preloader } from '../../components';
import { EnterLessonInitialPhase } from './EnterLessonInitialPhase';
import { useAuthStore } from '../../store/auth/AuthStore';
import { MessageWrapper } from './MessageWrapper';
import { EnterLessonPageTemplate } from './EnterLessonPageTemplate';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

export function EnterLessonPage() {

  const { lessonAuth, lessonAuthSettings, user } = useAuthStore();
  const [studentName, setStudentName] = useState<string>('');

  if (!lessonAuthSettings && !user)
    return <Preloader />;

  if (!lessonAuthSettings && user)
    return <NotFoundPage />

  if (lessonAuthSettings && lessonAuthSettings.error)
    return <MessageWrapper>{lessonAuthSettings.error}</MessageWrapper>

  return <EnterLessonPageTemplate>

      {lessonAuthSettings && (
        <EnterLessonInitialPhase
          studentName={studentName}
          setStudentName={setStudentName}
          password={lessonAuthSettings.password}
          nextStep={() => lessonAuth(studentName)} />
      )}

      {/*{phase === 'captcha' && (*/}
      {/*  <CaptchaStep*/}
      {/*    email={'userId'}*/}
      {/*    onSuccess={() => lessonAuth(studentName)}*/}
      {/*    confirmButtonText='Продолжить'*/}
      {/*  />*/}
      {/*)}*/}

    </EnterLessonPageTemplate>
}


