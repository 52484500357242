import React, { PropsWithChildren } from 'react';
import { TaskEditChessboard } from './components/TaskEditChessboard';
import { TaskEditFenButtons } from './components/TaskEditFenButtons';
import { TaskEditFenInput } from './components/TaskEditFenInput';
import { TaskEditNameInput } from './components/TaskEditNameInput';
import { TaskEditContainer } from './components/TaskEditContainer';
import { TaskEditMovesTable } from './components/TaskEditMovesTable';
import { TaskEditCastlingPanel } from './components/TaskEditCastlingPanel';
import { TaskEditDebugPanel } from './components/TaskEditDebugPanel';
import { TaskEditRank } from './components/TaskEditRank';
import { TaskEditFirstMove } from './components/TaskEditFirstMove';
import { TaskEditTaskTypeSelector } from './components/TaskEditTaskTypeSelector';
import { TaskEditDescription } from './components/TaskEditDescription';


export function TaskEditView(props: PropsWithChildren<{ }>) {
  return <TaskEditContainer>
    <div style={{ gridArea: 'L' }}>
      <TaskEditTaskTypeSelector/>
      <TaskEditNameInput />
      <TaskEditRank/>
      <TaskEditFenInput />
      <TaskEditCastlingPanel/>
      <TaskEditFenButtons />
      <TaskEditFirstMove/>
      <TaskEditMovesTable/>
      <TaskEditDescription/>
    </div>
    <div style={{ gridArea: 'R' }}>
      <TaskEditChessboard />
    </div>
    {/*<div style={{ gridArea: 'B' }}>*/}

    {/* */}
    {/*  /!*<TaskEditDebugPanel/>*!/*/}
    {/*</div>*/}
    <div style={{ gridArea: 'H' }}>
      {props.children}
    </div>
  </TaskEditContainer>;
}

