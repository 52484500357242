import styled from 'styled-components';
import { Button } from '../../components/low-level-components/Button';

const StyledMessage = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  padding: 0px;
  margin: 40px 0px 28px 0px;
  text-align: center;
`;

const StyledText = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  // margin-bottom: ${({ theme }) => theme.spacing.large}px;
  margin: 0px 0px 0px 0px;
  text-align: left;
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  height: 48px;
  padding: 13px;
`;

const LinkWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.large}px;

  a:hover {
    text-decoration: underline;
  }
`;


export const EnterLessonPageStyledComponents = {
  StyledButton, InputWrapper, StyledMessage, LinkWrapper, StyledText
}