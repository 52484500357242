import React, { PropsWithChildren } from 'react';
import { useLocalization } from '../../l10n/UseLocalization';
import { Grid } from '@material-ui/core';
import { TextLink } from '../../components/low-level-components/TextLink';
import { EnterLessonPageTemplate } from './EnterLessonPageTemplate';

export function MessageWrapper(props: PropsWithChildren<{}>) {
  const { auth } = useLocalization();

  return <EnterLessonPageTemplate>
    <div style={{
      width: '100%',
      textAlign: 'center',
      paddingTop: 30,
      fontSize: 30,
    }}>
      <Grid container justifyContent='center'>
        {props.children}
      </Grid>
    </div>
    <div style={{
      width: '100%',
      textAlign: 'center',
      paddingTop: 30,
    }}>
      <TextLink linkText={auth.home_page} to={'/'} />
    </div>
  </EnterLessonPageTemplate>;
}