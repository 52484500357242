import React from 'react';
import { LandingPageImages } from './LandingPageImages';
import { Theme, useThemeStore } from '../../store/themeStore';

export function LandingGirlPhoto() {
  const lightTheme = useThemeStore().theme === Theme.light;
  return <svg width="420" height="296" viewBox="0 0 420 296" overflow="visible">
      <rect width="580" height="296" rx="8" fill={ lightTheme ? "url(#paint001)": "url(#paint000)"}/>
      <defs>
        <radialGradient id="paint000" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(290.497 -19) rotate(90.0674) scale(422.668 1477.25)">
          <stop offset="0.333196" stopColor="#E4D7C8"/>
          <stop offset="1" stopColor="#533F2D"/>
        </radialGradient>
        <radialGradient id="paint001" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(290.498 -19) rotate(89.9991) scale(450 1572.78)">
          <stop offset="0.258196" stopColor="#DEDEDE"/>
          <stop offset="1" stopColor="#533F2D"/>
        </radialGradient>
      </defs>
    <image href={LandingPageImages.photoGirl}  />
    </svg>
}