import { L10n } from './L10n';

export const Lang_hi: L10n = {
  'landing': {
    'mainTitle': 'प्रशिक्षण<br/>एक नए स्तर पर',
    'mainSubTitle': '<br/>दूरस्थ शतरंज प्रशिक्षण प्लेटफार्म',
    'aboutUs': 'प्लेटफार्म के बारे में',
    'aboutUsText': '<b>ChessClub Training उन लोगों के लिए एक प्लेटफार्म है, जिनके लिए शतरंज केवल एक खेल नहीं है!</b><br/><br/>हम सीखने की प्रक्रिया को यथासंभव<br/>आरामदायक, प्रभावी और दिलचस्प बनाते हैं,<br/>चाहे वह शिक्षक के लिए हो या छात्रों के लिए।',
    'ourCapabilitiesTitle': 'हमारी क्षमताएँ',
    'ourCapabilitiesElements': [
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'व्यक्तिगत और समूह कक्षाओं का संचालन'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'बच्चों को शतरंज सिखाने में नए खेल रूपों का समावेश (गेमिफिकेशन)'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'सभी आवश्यक शिक्षण सामग्री एक ही स्थान पर उपलब्ध है'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'शिक्षण के लिए विस्तारित उपकरण'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'उपस्थिति के प्रभाव के साथ दूरस्थ शिक्षा'],
    ],
    'ourPlatformTitle': 'हमारा प्लेटफार्म आपके लिए है, यदि आप:',
    'ourPlatformElements': [
      ['https://chessclub.spb.ru/landing/img/knight_black.svg', 'https://chessclub.spb.ru/landing/img/knight_white.svg', 'शतरंज शिक्षा के क्षेत्र में फ्रीलांसिंग कर रहे हैं'],
      ['https://chessclub.spb.ru/landing/img/rook_black.svg', 'https://chessclub.spb.ru/landing/img/rook_white.svg', 'शतरंज क्लब में शिक्षक या पेशेवर शतरंज कोच हैं'],
      ['https://chessclub.spb.ru/landing/img/king_black.svg', 'https://chessclub.spb.ru/landing/img/king_white.svg', 'शतरंज पर मास्टर क्लास, व्याख्यान या इंटेंसिव आयोजित करते हैं'],
      ['https://chessclub.spb.ru/landing/img/queen_black.svg', 'https://chessclub.spb.ru/landing/img/queen_white.svg', 'चाहते हैं कि आपका बच्चा तार्किक रूप से सोच सके और शतरंज खेलना सीख सके'],
      ['https://chessclub.spb.ru/landing/img/pawn_black.svg', 'https://chessclub.spb.ru/landing/img/pawn_white.svg', 'एक कोच खोजने और शतरंज खेलना सीखने के इच्छुक हैं'],
    ],
    'ourAdvantagesTitle': 'हमारे लाभ',
    'ourAdvantagesElements': [
      ['https://chessclub.spb.ru/landing/img/pawns-6.svg', 'सम्मेलन मोड में समूह ऑनलाइन कक्षाओं का संचालन करने की क्षमता'],
      ['https://chessclub.spb.ru/landing/img/board.svg', 'विभिन्न कार्यों के लिए अनुकूलन विकल्पों के साथ अद्वितीय शतरंज बोर्ड'],
      ['https://chessclub.spb.ru/landing/img/arrows.svg', 'कर्सर और टुकड़ों की गति के साथ समन्वयित वर्चुअल पॉइंटर'],
      ['https://chessclub.spb.ru/landing/img/pawns-3.svg', 'विभिन्न आयु श्रेणियों के साथ कक्षाओं के लिए उपकरण'],
      ['https://chessclub.spb.ru/landing/img/ai.svg', 'कृत्रिम बुद्धिमत्ता प्रौद्योगिकियों का उपयोग'],
      ['https://chessclub.spb.ru/landing/img/pc.svg', 'PC, लैपटॉप, स्मार्टफोन, टैबलेट के लिए अनुकूलन इंटरफेस, मोबाइल संस्करण'],
      ['https://chessclub.spb.ru/landing/img/two-knights.svg', 'ऑनलाइन टूर्नामेंट आयोजित करने की क्षमता<br/>(विकास में)'],
      ['https://chessclub.spb.ru/landing/img/server.svg', 'TimeWeb सेवाओं के साथ सहयोग (विश्वसनीय सर्वर पर होस्टिंग)'],
    ],
    'ourExpertsTitle': 'हमारे विशेषज्ञ',
    'ourExpertsElements': [
      ['https://chessclub.spb.ru/landing/img/vladimir-bykov.png', '<b>व्लादिमीर बायकोव</b><br/><br/>सेंट पीटर्सबर्ग शतरंज संघ के कार्यकारी निदेशक। "कांस्य घुड़सवार" टीम के कप्तान। शतरंज में राष्ट्रीय श्रेणी के खेल न्यायाधीश।'],
      ['https://chessclub.spb.ru/landing/img/ekaterina-sudoplatova.png', '<b>एकातेरिना सुदोप्लातोवा</b><br/><br/>महिला ग्रैंडमास्टर। अंतरराष्ट्रीय मास्टर। रूस की दो बार की चैंपियन और लड़कियों के बीच विश्व चैंपियन, यूरोपीय चैंपियन, रूस की ओलंपिक टीम की सदस्य, महिलाओं के बीच रूस की चैंपियन, सेंट पीटर्सबर्ग की कई बार की चैंपियन। कोच, अतिरिक्त शिक्षा शिक्षक।'],
      ['https://chessclub.spb.ru/landing/img/alina-balayan.png', '<b>अलीना बलायन</b><br/><br/>महिला अंतरराष्ट्रीय मास्टर। शतरंज में रूस की खेल मास्टर। महिलाओं के बीच रैपिड में रूस की चैंपियन, टीम के हिस्से के रूप में रूस की चैंपियन, सेंट पीटर्सबर्ग की कई बार की चैंपियन। कोच, अतिरिक्त शिक्षा शिक्षक।'],
      ['https://chessclub.spb.ru/landing/img/valeriy-popov.png', '<b>वलेरी पॉपोव</b><br/><br/>ग्रैंडमास्टर। अंतरराष्ट्रीय मास्टर। यूरोपीय चैंपियन। उच्च श्रेणी के कोच। सेंट पीटर्सबर्ग राज्य बजटीय संस्थान के शतरंज विभाग के वरिष्ठ कोच।'],
      ['https://chessclub.spb.ru/landing/img/dina-belenkaya.png', '<b>डिना बेलेंकाया</b><br/><br/>महिला ग्रैंडमास्टर। टीम के हिस्से के रूप में रूस की चैंपियनशिप में कई बार पुरस्कार विजेता, सेंट पीटर्सबर्ग की कई बार की चैंपियन। शतरंज शिक्षक।'],
      ['https://chessclub.spb.ru/landing/img/anastasia-bondaruk.png', '<b>अनास्तासिया बोंडारुक</b><br/><br/>महिला ग्रैंडमास्टर। अंतरराष्ट्रीय मास्टर। रैपिड में विश्व चैंपियन, कई बार की रूस की चैंपियन, सेंट पीटर्सबर्ग की कई बार की चैंपियन, रूस की राष्ट्रीय टीम की सदस्य।'],
    ],
    'imagesSlider': [
      ['ऑनलाइन कक्षा', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/online-white.png'],
      ['ऑफलाइन कक्षा', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/offline-white.png'],
      ['कार्य संपादित करना', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/edit-white.png'],
    ]
  },
  'footer': {
    'help': 'सहायता',
    'contacts': 'संपर्क',
    'supportUs': 'परियोजना का समर्थन करें',
    'termsOfService': 'सेवा की शर्तें',
  },
  'rooms': {
    "rooms": 'कमरे',
    "new_room": "नया कमरा",
    "edit_room": "कमरे को संपादित करें",
    "name": "नाम",
    "description": "विवरण",
    "link": "लिंक",
    "external_view": "छात्र के कमरे का बाहरी दृश्य",
    "password_optional": "पासवर्ड (वैकल्पिक)",
    "trainees": "शिक्षार्थी",
    "nothing_found": "कुछ नहीं मिला",
    "search_by_site": "साइट द्वारा खोजें",
    "more_characters": " (3 या अधिक अक्षर दर्ज करें)",
  },
  'lesson': {
    'newTask': 'नया प्रश्न',
    'chain': 'श्रृंखला',
    'table': 'तालिका',
    'clear': 'स्पष्ट करें',
    'room': 'कमरा',
    'defaultRoom': 'डिफ़ॉल्ट कमरा',
    'ok': 'ठीक है',
    "empty": "कुछ भी नहीं मिला",
    'sizeHuge': 'विशाल',
    'sizeLarge': 'बड़ा',
    'sizeStandard': 'मानक',
    'markersBlue': 'नीला मार्कर',
    'markersGreen': 'हरा मार्कर',
    'markersOff': 'मार्कर बंद करें',
    'markersRed': 'लाल मार्कर',
    'markersDelete': 'मार्कर हटाएं',
    'withoutTitles': 'बिना शीर्षकों के',
    'titlesAtCellCorners': 'कोष्ठकों के कोनों में',
    'titlesInEveryCell': 'हर कोशिका में',
    'titlesAroundChessboard': 'बोर्ड के चारों ओर',
    'standardView': 'मानक',
    'allChessboardsView': 'सभी बोर्ड',
    'allCamsView': 'सभी कैमरे',
    'size': 'आकार',
    'exit': 'बाहर निकलें',
    "knowledges": "ज्ञान आधार",
    "materials": "सामग्री",
    "my_materials": "मेरे सामग्री",
    'rooms': 'कमरे',
    'profile': 'प्रोफ़ाइल',
    'edit': 'संपादित करें',
    'paste': 'चिपकाएँ',
    'copy': 'कॉपी करें',
    'save': 'सहेजें',
    'cancel': 'रद्द करें',
    'delete': 'हटाएं',
    'add': 'जोड़ें',
    'waitForTrainer': 'कोच के आने की प्रतीक्षा करें',
    'chat': 'चैट',
    'cams': 'कैमरे',
    'online': 'ऑनलाइन',
    'view': 'दृश्य',
    'demonstration': 'प्रदर्शन',
    'settings': 'सेटिंग्स',
    'offline': 'ऑफलाइन',
    'black': 'काले',
    'white': 'सफेद',
    'tasks': 'प्रश्न',
    'moves': 'चालें',
    'activeLessons': 'सक्रिय पाठ',
    'activeLesson': 'सक्रिय पाठ',
    'student': 'छात्र',
    'trainer': 'कोच',
    'lesson': 'पाठ',
    "lessons": "पाठ",
    "beginning": "शुरुआत",
    "name": "नाम",
    "task": "उत्तर के साथ कार्य",
    "game": "नायकों के साथ खेल",
    "question": "विवरण के साथ प्रश्न",
    "engine": "कंप्यूटर के साथ खेल",
    "edit_task": "कार्य संपादित करना",
    "search": "खोज",
    "edit_material": "सामग्री संपादित करना",
    "new_topic": "नया विषय जोड़ें",
    "reset": "रीसेट करें",
  },
  'captcha': {
    'language': 'हिंदी',
    'task' : "कृपया कार्य पूरा करें ताकि हम जान सकें कि आप एक असली व्यक्ति हैं:",
    'login': 'लॉगिन',
    'sign_up': 'साइन अप करें',
    'errors': {
      'NO_ERROR': 'कोई त्रुटि नहीं मिली',
      'INVALID_PARAMS': 'अमान्य कैप्चा पैरामीटर',
      'WRONG_PROTOBUF_MESSAGE': 'गलत protobuf संदेश',
      'CAPTCHA_NOT_FOUND': 'कैप्चा नहीं मिला',
      'CAPTCHA_ALREADY_EXISTS': 'अमान्य कैप्चा पैरामीटर',
      'WRONG_CAPTCHA_EMAIL': 'गलत कैप्चा ईमेल',
      'CAPTCHA_IS_EXPIRED': 'कैप्चा की अवधि समाप्त हो गई है',
      'ANSWER_IS_EXPIRED': 'उत्तर की अवधि समाप्त हो गई है',
      'WRONG_CAPTCHA_ANSWER': 'गलत कैप्चा उत्तर',
      'WRONG_FEN_RANDOMIZED': 'FEN जनरेशन त्रुटि',
    },
    questions: {
      "Find the white pawns": "सफेद प्यादों को खोजें",
      "Find the black pawns": "काले प्यादों को खोजें",
      "Find the pawns": "सभी प्यादों को खोजें",
      "Find the white knights": "सफेद घोड़ों को खोजें",
      "Find the black knights": "काले घोड़ों को खोजें",
      "Find the knights": "सभी घोड़ों को खोजें",
      "Find the white bishops": "सफेद ऊँटों को खोजें",
      "Find the black bishops": "काले ऊँटों को खोजें",
      "Find the bishops": "सभी ऊँटों को खोजें",
      "Find the white rooks": "सफेद गढ़ों को खोजें",
      "Find the black rooks": "काले गढ़ों को खोजें",
      "Find the rooks": "सभी गढ़ों को खोजें",
      "Find the white queens": "सफेद रानियों को खोजें",
      "Find the black queens": "काले रानियों को खोजें",
      "Find the queens": "सभी रानियों को खोजें",
      "Find the kings": "सभी राजाओं को खोजें",
    },
  },
  'auth': {
    'new_password': 'नया पासवर्ड',
    'repeat_new_password': 'नया पासवर्ड दोहराएँ',
    'consider_new_password': 'एक नया पासवर्ड बनाएं और पुष्टि के लिए उस कोड को दर्ज करें जो आपके ईमेल पर भेजा गया था',
    'confirm': 'पुष्टि करें',
    'agree_with': 'मैं सहमत हूँ ',
    'terms': 'सेवा की शर्तें',
    'do_sign_up': 'साइन अप करें',
    'continue': 'जारी रखें',
    'code': 'ईमेल से कोड',
    'resend_code': 'कोड फिर से भेजें',
    'email_to_restore': 'पहुंच बहाल करने के लिए ईमेल पता दर्ज करें',
    'receive_code_in_mail': 'ईमेल में कोड प्राप्त करें',
    'repeat_password': 'पासवर्ड दोहराएँ',
    'login_with': 'के साथ लॉगिन करें',
    'login': 'लॉगिन',
    'forgot_password': 'पासवर्ड पुनर्प्राप्त करें',
    'or': 'या',
    'email': 'ईमेल',
    'wrong_email': 'गलत ईमेल',
    'password': 'पासवर्ड',
    "password_not_match":"पासवर्ड मेल नहीं खाते",
    'no_account_yet': 'अभी तक कोई खाता नहीं है?',
    'sign_up': 'साइन अप करें',
    'yandex': 'यांडेक्स',
    'ok': 'ओडनोक्लास्निकी',
    'mail': 'मेल.रु',
    'fb': 'फेसबुक',
    'twitter': 'ट्विटर',
    'vk': 'वीके',
    'google': 'गूगल',
    'choose_lg': 'अपनी भाषा चुनें',
    'language': 'हिंदी',
    "home_page": "होम पेज पर",
    "page_not_found": "पृष्ठ नहीं मिला",
    "lesson_not_found": "पाठ नहीं मिला",
    'errors': {
      'NO_ERROR': 'कोई त्रुटि नहीं',
      'USER_NOT_FOUND': 'उपयोगकर्ता नहीं मिला',
      'USER_ALREADY_EXISTS': 'उपयोगकर्ता पहले से मौजूद है',
      'INVALID_PARAMS': 'अमान्य पहुंच पैरामीटर',
      'USER_NOT_ACTIVE': 'उपयोगकर्ता सक्रिय नहीं है',
      'WRONG_PASSWORD': 'गलत पासवर्ड',
      'WRONG_ACTIVATION_CODE': 'गलत सक्रियण कोड',
      'WRONG_SESSION_SIGN': 'गलत सत्र संकेत',
      'NOT_AUTHENTICATED': 'उपयोगकर्ता प्रमाणित नहीं है',
      'WRONG_RESTORE_CODE': 'गलत पुनर्स्थापना कोड',
      'USER_ALREADY_ACTIVE': 'उपयोगकर्ता पहले से सक्रिय है',
      'WRONG_AUTH_CODE': 'गलत प्रमाणीकरण कोड',
      'IMPROPERLY_CONFIGURED': 'गलत सेटिंग्स प्रदान की गई हैं',
      'WRONG_PROVIDER': 'गलत सामाजिक प्रदाता',
      'WEAK_PASSWORD': 'पासवर्ड बहुत कमजोर है',
      'SOCIAL_AUTH_ERROR': 'उपयोगकर्ता जानकारी प्राप्त करने में त्रुटि',
      'OAUTH_APP_ALREADY_EXISTS': 'OAuth एप्लिकेशन पहले से मौजूद है',
      'OAUTH_APP_NOT_FOUND': 'OAuth एप्लिकेशन नहीं मिला',
      'OAUTH_CONSUMER_NOT_FOUND': 'OAuth उपभोक्ता नहीं मिला',
      'OAUTH_MAX_APPS_REACHED': 'OAuth एप्लिकेशनों की अधिकतम संख्या तक पहुँच गई है',
      'OAUTH_CODE_NOT_FOUND': 'OAuth प्राधिकरण कोड नहीं मिला',
      'OAUTH_ACCESS_TOKEN_NOT_FOUND': 'OAuth एक्सेस टोकन नहीं मिला',
      'SMTP_SEND_MAIL_ERROR': 'SMTP मेल भेजने में त्रुटि',
      'WRONG_IMAGE_TYPE': 'गलत छवि प्रकार',
      'SEND_IMAGE_ERROR': 'छवि भेजने में त्रुटि',
      'WRONG_LANGUAGE_TYPE': 'गलत भाषा प्रकार',
      'WRONG_DECODE_DATA': 'डेटा डिकोड करने में त्रुटि',
      'WRONG_ENCODE_DATA': 'डेटा एन्कोड करने में त्रुटि',
      'WRONG_PROTOBUF_MESSAGE': 'गलत protobuf संदेश',
      'PERMISSION_DENIED': 'अनुमति अस्वीकृत',
      'CONTACT_ALREADY_EXISTS': 'संपर्क पहले से मौजूद है',
      'CONTACT_NOT_FOUND': 'संपर्क नहीं मिला',
      'CONTACT_NOT_ACTIVE': 'संपर्क सक्रिय नहीं है',
    },
  },
  'permissions': {
    'OtherError': {
      'otherError': 'आपका कैमरा उपलब्ध नहीं है। कृपया एक अन्य उपकरण का उपयोग करें।',
    },
    'BlockedAtSystemLevelMacOs': {
      'camera': 'कैमरा कनेक्ट नहीं है, या {{browserName}} के लिए कैमरे तक पहुंच ऑपरेटिंग सिस्टम स्तर पर अवरुद्ध है।',
      'check': 'जांचें कि क्या कैमरा अन्य अनुप्रयोगों (जैसे, फोटो बूथ) में काम कर रहा है। यदि कैमरा ठीक है, तो सुनिश्चित करें कि {{browserName}} को कैमरे तक पहुंच है।',
      'p1_menu': 'एप्पल मेनू चुनें',
      'p1_pref': '→ "सिस्टम प्राथमिकताएँ", "सुरक्षा और गोपनीयता" पर क्लिक करें, फिर "गोपनीयता" पर क्लिक करें;',
      'p2': '“कैमरा” चुनें, {{browserName}} एप्लिकेशन के बगल में चेक बॉक्स को चेक करें;',
      'p3': '“माइक्रोफोन” चुनें, {{browserName}} एप्लिकेशन के बगल में चेक बॉक्स को चेक करें;',
      'p4': '{{browserName}} को पुनः प्रारंभ करें और फिर से साक्षात्कार देने का प्रयास करें।',
      'more': 'वेबसाइट पर अधिक जानकारी',
      'support': 'एप्पल समर्थन',
    },
    'NoSoundWindowsFirefox': {
      'sureConnected': 'यदि आप सुनिश्चित हैं कि आपका माइक्रोफोन कनेक्टेड है, तो संभवतः Windows की गोपनीयता सेटिंग्स में इसकी पहुंच अवरुद्ध है।',
      'goToStart': 'शुरू करें पर जाएं',
      'parameters': 'सेटिंग्स',
      'privacy': 'गोपनीयता',
      'mic': 'माइक्रोफोन। सुनिश्चित करें कि:',
      'p1': 'इस डिवाइस के लिए माइक्रोफोन की पहुंच सक्षम है;',
      'p2': '“ऐप्स को माइक्रोफोन तक पहुंच की अनुमति दें” चालू स्थिति में है;',
      'p3': '“क्लासिक ऐप्स को माइक्रोफोन तक पहुंच की अनुमति दें” (यदि है) चालू स्थिति में है;',
      'p4': 'सेटिंग्स में बदलाव करने के बाद, पृष्ठ को ताज़ा करें।',
      'more': 'वेबसाइट पर अधिक जानकारी',
      'support': 'माइक्रोसॉफ्ट समर्थन',
    },
    'NotAllowedAndroidChrome': {
      'check': 'कैमरा और माइक्रोफोन तक पहुंच प्राप्त नहीं हो सका।',
      'p1': 'वेबसाइट के पते के बाईं ओर, ताले के आइकन पर क्लिक करें',
      'p2': 'अनुमतियों के अनुभाग पर क्लिक करें;',
      'p3': 'कैमरा और माइक्रोफोन तक पहुंच की अनुमति दें;',
      'p4': 'पृष्ठ को पुनः लोड करें।',
      'i1': 'यदि आप अनुमतियों का अनुभाग नहीं देखते हैं, तो इसका मतलब है कि कैमरा और माइक्रोफोन की पहुंच Android ऑपरेटिंग सिस्टम स्तर पर अवरुद्ध है। Android सेटिंग्स में ऐप्स अनुभाग में सुनिश्चित करें कि Chrome को कैमरा और माइक्रोफोन तक पहुंच की अनुमति है। इसके बाद, Chrome पर वापस जाएं और पृष्ठ को पुनः लोड करें। वेबसाइट पर अधिक जानकारी',
      'more': 'Android समर्थन',
    },
    'NotAllowedBlink': {
      'check': 'आपने कैमरा या माइक्रोफोन तक पहुंच की अनुमति नहीं दी।',
      'p1': 'वेबसाइट के पते के बाईं ओर, ताले के आइकन पर क्लिक करें',
      'p2': 'कैमरा और माइक्रोफोन तक पहुंच की अनुमति दें;',
      'p3': 'पृष्ठ को ताज़ा करें',
      'p3_check': 'या यहां क्लिक करें',
    },
    'NotAllowedBlinkWindowsSystem': {
      'check': 'माइक्रोफोन/कैमरा तक पहुंच प्राप्त नहीं हो सका। संभवतः Windows 10 की गोपनीयता सेटिंग्स में माइक्रोफोन की पहुंच अवरुद्ध है।',
      'goToStart': 'शुरू करें पर जाएं',
      'parameters': 'सेटिंग्स',
      'privacy': 'गोपनीयता',
      'cameraAndSure': 'माइक्रोफोन अनुभाग पर जाएं, फिर कैमरा और सुनिश्चित करें कि डिवाइस की पहुंच अवरुद्ध नहीं है:',
      'p1': 'इस डिवाइस के लिए माइक्रोफोन/कैमरा की पहुंच सक्षम है;',
      'p2': '“ऐप्स को कैमरा तक पहुंच की अनुमति दें” / “ऐप्स को माइक्रोफोन तक पहुंच की अनुमति दें” चालू स्थिति में है;',
      'p3': '“क्लासिक ऐप्स को कैमरा तक पहुंच की अनुमति दें” / “क्लासिक ऐप्स को माइक्रोफोन तक पहुंच की अनुमति दें” (यदि है) चालू स्थिति में है;',
      'p4': 'सेटिंग्स में बदलाव करने के बाद, पृष्ठ को ताज़ा करें।',
      'more': 'वेबसाइट पर अधिक जानकारी',
      'support': 'माइक्रोसॉफ्ट समर्थन',
    },
    'NotAllowedFirefox': {
      'check': 'आपने कैमरा या माइक्रोफोन तक पहुंच की अनुमति नहीं दी।',
      'p1': 'वेबसाइट के पते के बाईं ओर, क्रॉस-आउट कैमरा या माइक्रोफोन आइकन पर क्लिक करें;',
      'p2': 'कैमरा और माइक्रोफोन तक पहुंच पर प्रतिबंध को रीसेट करें;',
      'p3': 'पृष्ठ को ताज़ा करें;',
      'p4': 'जब ब्राउज़र फिर से पहुंच के लिए अनुरोध करता है, तो अनुमति पर क्लिक करें।',
      'more': 'यदि सिफारिशें मदद नहीं करती हैं, तो उपयोग करें',
      'support': 'Firefox दस्तावेज़',
    },
    'NotAllowedMacOsSafari': {
      'check': 'आपने कैमरा या माइक्रोफोन तक पहुंच की अनुमति नहीं दी।',
      'p1': '“Safari” मेनू चुनें → “प्राथमिकताएँ” और “वेबसाइट” पर क्लिक करें;',
      'p2': 'बारी-बारी से कैमरा और माइक्रोफोन चुनें और वेबसाइट huntica.works के लिए “अनुमति दें” सेट करें;',
      'p3': 'सेटिंग्स बंद करें और पृष्ठ को पुनः लोड करें।',
      'more': 'वेबसाइट पर अधिक जानकारी',
      'support': 'एप्पल समर्थन',
    },
    'NotConnected': {
      'notConnected': 'ऐसा लगता है कि आपका कैमरा कनेक्ट नहीं है। कृपया कैमरा कनेक्ट करें।',
    },
    'NotReadableErrorAudioWindows': {
      'check': 'माइक्रोफोन तक पहुंच प्राप्त नहीं हो सका। संभवतः Windows 10 की गोपनीयता सेटिंग्स में माइक्रोफोन की पहुंच अवरुद्ध है।',
      'goToStart': 'शुरू करें पर जाएं',
      'parameters': 'सेटिंग्स',
      'privacy': 'गोपनीयता',
      'mic': 'माइक्रोफोन। सुनिश्चित करें कि:',
      'p1': 'इस डिवाइस के लिए माइक्रोफोन की पहुंच सक्षम है;',
      'p2': '“ऐप्स को माइक्रोफोन तक पहुंच की अनुमति दें” चालू स्थिति में है;',
      'p3': '“क्लासिक ऐप्स को माइक्रोफोन तक पहुंच की अनुमति दें” (यदि है) चालू स्थिति में है;',
      'p4': 'सेटिंग्स में बदलाव करने के बाद, पृष्ठ को ताज़ा करें',
      'more': 'वेबसाइट पर अधिक जानकारी',
      'support': 'माइक्रोसॉफ्ट समर्थन',
    },
    'NotReadableErrorVideoWindows': {
      'check': 'कैमरा तक पहुंच प्राप्त नहीं हो सका। संभावित कारण:',
      'p1': 'कैमरा वर्तमान में किसी अन्य एप्लिकेशन (जैसे, Skype या Zoom) द्वारा उपयोग किया जा रहा है। आमतौर पर, इस स्थिति में कैमरे पर संकेतक जलता है। सभी एप्लिकेशन बंद करें जो कैमरा का उपयोग कर सकते हैं, फिर पृष्ठ को पुनः लोड करें।',
      'p2': 'यदि आप सुनिश्चित हैं कि कैमरा वर्तमान में उपयोग में नहीं है, तो इसकी पहुंच संभवतः Windows 10 की गोपनीयता सेटिंग्स में अवरुद्ध है।',
      'goToStart': 'शुरू करें पर जाएं',
      'parameters': 'सेटिंग्स',
      'privacy': 'गोपनीयता',
      'cameraAndSure': 'कैमरा और सुनिश्चित करें कि:',
      'a': 'इस डिवाइस के लिए कैमरे की पहुंच सक्षम है;',
      'b': '“ऐप्स को कैमरा तक पहुंच की अनुमति दें” चालू स्थिति में है;',
      'c': '“क्लासिक ऐप्स को कैमरा तक पहुंच की अनुमति दें” (यदि है) चालू स्थिति में है;',
      'd': 'सेटिंग्स में बदलाव करने के बाद, पृष्ठ को ताज़ा करें।',
      'more': 'वेबसाइट पर अधिक जानकारी',
      'support': 'माइक्रोसॉफ्ट समर्थन',
    },
  },
  'termsOfService': {
    'header': 'सेवा की शर्तें',
    'parts': [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
    ],
  },
  "settings": {
    "personalInfo": "प्रोफ़ाइल",
    "security": "सुरक्षा",
    "decoration": "थीम",
    "userName": "उपयोगकर्ता नाम",
    "firstName": "पहला नाम",
    "lastName": "अंतिम नाम",
    "dateOfBirth": "जन्म तिथि",
    "town": "शहर",
    "email": "ईमेल",
    "sendingMessages": "सेवा की समाचार पत्रिकाएँ प्राप्त करें",
    'password': 'पासवर्ड',
    'repeat_password': 'पासवर्ड दोहराएँ',
    "light": "हल्का",
    "dark": "गहरा",
    "os_theme": "स्वचालित रूप से OS थीम का उपयोग करें",
    "theme": "रंग थीम",
    "active_color": "सक्रिय तत्व का रंग",
    "moves_style": "बोर्ड पर टुकड़ों को स्थानांतरित करना",
    "drag": "खींचना",
    "click": "माउस क्लिक",
    "flip": "उलटना",
    "rotate": "घुमाना",
    "cancel": "रद्द करें",
  },
  "textEditor": {
    "titles": {
      "text1": "शीर्षक 1",
      "text2": "शीर्षक 2",
      "text3": "शीर्षक 3",
      "text4": "शीर्षक 4",
      "text0": "सामान्य पाठ",
      "title": "शीर्षक"
    },
  },
};