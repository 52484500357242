import { Grid, InputAdornment } from '@material-ui/core';
import Input from '../../components/low-level-components/Input';
import React, { FormEvent, useState } from 'react';
import PasswordInput from '../../components/low-level-components/PasswordInput';
import { EnterLessonPageStyledComponents } from './EnterLessonPageStyledComponents';
import { IInviteForm, IInviteFormErrors } from '../../interfaces/invite-form';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { Link } from '../../components/low-level-components/Link';
import { useLocalization } from '../../l10n/UseLocalization';

const {
  StyledButton, InputWrapper, StyledMessage, LinkWrapper, StyledText
} = EnterLessonPageStyledComponents;

export function EnterLessonInitialPhase({ password , nextStep, setStudentName, studentName}: { password, nextStep, setStudentName ,studentName}) {

  const { auth } = useLocalization();
  const initialFormData: IInviteForm = { name: studentName, password: '' };
  const initialErrors: IInviteFormErrors = { name: false, password: false };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);


  const handleClick = () => {
    let errors: IInviteFormErrors = {};
    if (!formData.name)
      errors.name = true;
    if (password) {
      if (!formData.password || formData.password !== password)
        errors.password = true;
    }
    if (!Object.values(errors).length) {
      nextStep();
    }
    setErrors(errors);
  };

  return <>
    <Grid container justifyContent='center'>
      <StyledMessage>
        Вы переходите по ссылке на занятие.<br/>Для доступа к кабинету введите свои данные
      </StyledMessage>
    </Grid>
    <Grid container justifyContent='center' direction='column'>
      <InputWrapper>
        <Input
          label='Имя'
          fullWidth
          value={studentName}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setFormData((prev) => ({ ...prev, name: (e.target as HTMLInputElement).value }));
            setStudentName((e.target as HTMLInputElement).value);
          }}
          error={errors.name}
          endAdornment={
            studentName ? (
              <InputAdornment
                onClick={() => setStudentName('')}
                style={{ paddingRight: '2px' }}
                position='start'
              >
                <SvgImage icon={KnownIcons.cross}/>
              </InputAdornment>
            ) : null
          }
        />
      </InputWrapper>
      {password && (
        <InputWrapper>
          <PasswordInput
            label='Пароль доступа к кабинету'
            fullWidth
            value={formData.password}
            onChange={(e: any) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
            error={errors.password}
          />
        </InputWrapper>
      )}

      <StyledButton
        fullWidth
        onClick={handleClick}>
        Продолжить без регистрации
      </StyledButton>

    </Grid>
  </>;
}