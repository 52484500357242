import { useTasksStore } from '../tasks/TasksStore';
import { useLessonStore } from '../lesson/LessonStore';
import { useAuthStore } from '../auth/AuthStore';
import { ServerApi } from '../../transport/ServerApi';
import { ITask, RoomId, UserId } from '@chessclub/grpc_wrapper';
import { wgs, wgsStartTask } from '../../app/workers';
import { centrifugeUrl } from '../../config';

export async function enterLessonRoutine(roomId: RoomId) {
  console.log('enter lesson: ', roomId);

  if (useLessonStore.getState().roomId === roomId)
    return;

  const user = useAuthStore.getState().user;
  const tasks = await ServerApi.tasksService.getRoomTasks(roomId, user.id);
  const params = { roomId, userId: user.id, tasks };

  await useLessonStore.getState().enterLesson(params);
  await useTasksStore.getState().loadTasksForUserInRoom(params);

  wgs.start({
    token:user.session,
    centrifugeUrl
  }); // start web game server at worker thread
  const roomOwnerId = useLessonStore.getState().teacherId;
  tasks.forEach(t => {
    wgsStartTask(t, roomOwnerId, user.id); // start user's tasks at web game server
  });
}

