import React, { useMemo, useRef } from 'react';
import { TextEllipsis } from '../../components/low-level-components/EllipsedText';
import { eventPath } from '../../helpers/eventPath';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons, randomIcon } from '../../components/Icon/KnownIcons';
import { useRoomsStore } from '../../store/rooms/RoomsStore';
import { useHistory } from 'react-router-dom';
import { enterLessonRoutine } from '../../store/actions/enterLessonRoutine';
import { IRoom } from '../../interfaces/room';
import { ConfirmationPopupMenu } from '../../components/PopupMenu/ConfirmationPopupMenu';
import { OnlineButton } from '../../components/OnlineButton/OnlineButton';
import { Stack } from '../../components/low-level-components/Stack';
import { RawSvg } from '../../components/Icon/RawSvg';
import styled from 'styled-components';
import { Card } from '../../components/low-level-components/Card';
import { useEditingRoomStore } from '../../store/editing-room/EditingRoomStore';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useLocalization } from '../../l10n/UseLocalization';

export interface IContainer {
  enabled?: boolean;
}

const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
    padding: ${p => p.theme.spacing.medium}px;
`;

const StyledHeaderIcons = styled.span`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 5px;
  }
`;

const StyledBody = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const StyledFooter = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 7px 15px;
`;

const StyledActions = styled.span`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;
  }

  & > *:first-child {
    margin-right: 16px;
  }
`;


const RoomEntryStyledContainer = styled(Stack)`

  cursor: pointer;
  //height: 144px;
  position: relative;
`;

const Container = styled(Stack)`
  box-shadow: 1px 1px 5px var(--color-icon-light);
  border-radius: 12px;
  transition: 0.1s ;
  
  &:hover {
    box-shadow: 3px 3px 5px var(--color-icon-light);
    transform: translate(-1px, -2px) rotate3d(1,2,1,-1deg );
    perspective: 1001px;
  }
  
  //& .bg {
  //  transition: 0.2s ;
  //}
  //
  //&:hover .bg {
  //  transform: translate(10px,0) scale(0.95);
  //}
`


const RoomCardHiddenButtons = styled.div`
  opacity: 0;
  transition: 0.2s;
  display: flex;
  ${RoomEntryStyledContainer}:hover & {
    opacity: 1;
  }
`

const StyledTitle = styled.span<IContainer>`
  min-width: 0;
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  ${({ enabled }) => (!enabled ? 'font-weight: 300;' : '')}
`;

const StyledDescription = styled.span`
  margin: 0;
  padding: 0;
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  min-width: 0;
`;

export function RoomCard(props: IRoom) {
  const {isActive, userId, id, name, password, description, anyStudentPresents, coach} = props;
  const {user} = useAuthStore();

  const {deleteRoom} = useRoomsStore();
  const {startEdit} = useEditingRoomStore();
  const history = useHistory();

  async function goToLesson() {
    await enterLessonRoutine(id)
    history.push(`/lesson/${id}`)
  }

  // function handleCopyLink() {
  //   return navigator.clipboard.writeText(document.location.origin + `/lesson/${roomCard.id}`);
  // }
  const { lesson } = useLocalization();

  const canChange = user?.id === userId;
  let onlineImage = <SvgImage r={16} filled={isActive} icon={KnownIcons.power_button}
                           tooltip={isActive ? lesson.online: lesson.offline} />;
  return <Card accent={isActive}>
    <RoomEntryStyledContainer onClick={goToLesson}>
      <div style={{ height: 144, overflow: "hidden", opacity: 0.2 }}>
        <RawSvg rawSvg={KnownIcons[props.customData?.icon || "plus"]} width={200} className={"bg"} />
      </div>
      <StyledHeader>
        <StyledTitle>
          <TextEllipsis lineClamp={2} text={canChange ? name : `${name} (${coach})`} />
        </StyledTitle>
        <StyledHeaderIcons>
          {password && <SvgImage icon={KnownIcons.lock} size={24} />}
          <SvgImage icon={KnownIcons.human} filled={isActive && !!anyStudentPresents} size={24} />
        </StyledHeaderIcons>
      </StyledHeader>
      <StyledBody>
        <StyledDescription>
          <TextEllipsis lineClamp={2} text={description} />
        </StyledDescription>
      </StyledBody>
      <StyledFooter enabled={isActive}>
        <StyledActions>
          {canChange && <RoomCardHiddenButtons>

            <ConfirmationPopupMenu
              text={lesson.delete}
              action={() => deleteRoom(id)}
              disabled={isActive}
            >
              <SvgImage icon={KnownIcons.trash} withHover tooltip={lesson.delete} />
            </ConfirmationPopupMenu>

            <div style={{ width: 10 }} />

            <SvgImage icon={KnownIcons.pencil} withHover tooltip={lesson.edit}
                      onClick={() => startEdit(props)} />

            {/*<SvgImage icon={KnownIcons.copy_settings} withHover onClick={handleCopyLink} />*/}
          </RoomCardHiddenButtons>}
        </StyledActions>

        {canChange && <OnlineButton roomId={id}>
          {onlineImage}
        </OnlineButton> }

      </StyledFooter>
    </RoomEntryStyledContainer>
  </Card>;
}