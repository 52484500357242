import React from 'react';
import { PageHeaderStyledComponents } from '../../components/PageHeader/PageHeaderStyledComponents';
import { L10n } from '../../l10n/L10n';
import { useLocalization } from '../../l10n/UseLocalization';

const {
  StyledNavItems,
  StyledNavItem,
} = PageHeaderStyledComponents;

export enum LandingPageNavigationIndex {
  about = 'about',
  advantages = 'advantages',
  experts = 'experts'
}

const NavigationTitles = {
  [LandingPageNavigationIndex.about]: (lang: L10n) => lang.landing.aboutUs,
  [LandingPageNavigationIndex.advantages]: (lang: L10n) => lang.landing.ourAdvantagesTitle,
  [LandingPageNavigationIndex.experts]: (lang: L10n) => lang.landing.ourExpertsTitle,
};

export function LandingPageNavigation() {
  const lang = useLocalization();
  return <StyledNavItems style={{ paddingLeft: 50 }}>
    {Object.keys(LandingPageNavigationIndex).map(nav => {
      return <StyledNavItem key={nav} to={''} onClick={() => scrollTo(nav)}>
        {NavigationTitles[nav](lang)}
      </StyledNavItem>
    })}
  </StyledNavItems>;
}

function scrollTo(className: string) {
  let el = document.querySelector('.' + className) as HTMLDivElement;
  document.querySelector('#root > div').scrollTo({
    top: el.offsetTop - 150,
    behavior: 'smooth',
  });
}