import { Room, RoomSession } from '@chessclub/video-conf';
import React, { useEffect, useState } from 'react';
import {  ChessclubUser } from '../../../../../../../interfaces/user';
import {useConferenceStore} from "../../../../../../../store/conferenceStore";
import { CameraCardBase } from '../index';
import { UserId } from '@chessclub/grpc_wrapper';
import { conferenceStateDb } from '../conferenceStateDb';
import { CameraCardAvatar } from '../components/CameraCardAvatar';

type RemoteCameraCardProps = {
  session: RoomSession;
  user: ChessclubUser;
  room: Room;
  canMove: boolean;
};

export function ActiveRemoteCameraCard(props: RemoteCameraCardProps) {
  const { session, user, room } = props;
  const [ currentSession, setSession ] = useState<RoomSession>({...session});
  const { addUpdatedSessions, toggleCameraEnabledBySocketId, toggleMicroEnabledBySocketId } = useConferenceStore();

  const [cam, setCam] = useState<boolean>();
  const [mic, setMic] = useState<boolean>();

  async function restoreConferenceState(userId: UserId) {
    setCam(await conferenceStateDb.isCam(userId))
    setMic(await conferenceStateDb.isMic(userId))
  }

  useEffect(() => {
    const handler = (updatedSession: RoomSession) => {
      if (currentSession!.user !== updatedSession.user) {
        return;
      }
      addUpdatedSessions(updatedSession);
      setSession({ ...updatedSession });
      // toggleCameraEnabledBySocketId({
      //   socketId: updatedSession.user,
      //   enabled: !!updatedSession.video
      // })
      // toggleMicroEnabledBySocketId({
      //   socketId: updatedSession.user,
      //   enabled: !!updatedSession.audio
      // })
    };
    restoreConferenceState(user.id)
    room.emitter?.on('session-updated', handler);
    return () => {
      room.emitter?.off('session-updated', handler);
    };
  }, []);

  async function toggleCameraEnabled() {
    await conferenceStateDb.setCam(user.id, !cam);
    setCam(!cam)
    // session.pc.toggleRemoteVideo();
    // toggleCameraEnabledBySocketId({
    //   socketId: user.socketId,
    //   enabled: currentSession.video,
    // });
  }

  async function toggleMicroEnabled() {
    await conferenceStateDb.setMic(user.id, !mic);
    setMic(!mic)
    // session.pc.toggleRemoteMute();
    // toggleMicroEnabledBySocketId({
    //   socketId: user.socketId,
    //   enabled: currentSession.audio,
    // });
  }

  return <CameraCardBase
    toggleCameraEnabled={toggleCameraEnabled}
    toggleMicroEnabled={toggleMicroEnabled}
    canMove={props.canMove}
    showDisabledMedia={true}
    cameraEnabled={currentSession.video}
    localCameraEnabled={cam}
    microEnabled={currentSession.audio}
    localMicroEnabled={mic}
    stream={session.pc.stream}
    pc={session.pc}
    user={user}
  >
    <CameraCardAvatar user={user} />
  </CameraCardBase>;
}


