import { useAuthStore } from '../../store/auth/AuthStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Role } from '@chessclub/grpc_wrapper';
import { Link } from '../low-level-components/Link';
import { Box } from '@material-ui/core';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import { UserProfilePopupMenu } from './UserProfilePopupMenu';
import { UserProfile } from './UserProfile/UserProfile';
import React from 'react';
import { TextLink } from '../low-level-components/TextLink';

export function UserProfileWidget() {

  const { user } = useAuthStore();
  const { auth } = useLocalization();

  if (!user || user.role === Role.GUEST) {
    return <>
      <div style={{ display: 'flex', gap: 20 }}>
        <TextLink linkText={auth.sign_up} to={"/auth/register/" + document.location.pathname.split("/").pop()}/>
        <TextLink linkText={auth.login} to={"/auth/login/" + document.location.pathname.split("/").pop()}/>
      </div>
    </>;
  }

  return <div>
    <Box display={'flex'} justifyContent={'end'}>
      <PopupMenu popupContent={UserProfilePopupMenu}>
        <UserProfile />
      </PopupMenu>
    </Box>
  </div>;
}