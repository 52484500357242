import { useEditingTopicStore } from '../../store/editing-topic/EditingTopicStore';
import { newMaterialTopic } from '../../pages/MaterialsPage/newMaterialTopic';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { TextNode } from '../../components/low-level-components/TextNode';
import React from 'react';
import styled from 'styled-components';
import { ITopic } from '@chessclub/grpc_wrapper';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { useLocalization } from '../../l10n/UseLocalization';

const AddTopicEl = styled.div`
  display: flex;
  border: 2px dashed var(--color-icon-main);
  cursor: pointer;
  mask-image: linear-gradient(160deg, #fff 60%, #fff1 80%);
  transition: 0.5s;
  mask-size: 200% 100%;
  mask-position: 100% 0;
  margin: 3px;

  &:hover {
    mask-position: 0 0;
    padding-left: 5px;
  }

  border-radius: 6px;
`;

export function AddTopicElement(props: { topicFactory: () => ITopic }) {
  const { startEditTopic } = useEditingTopicStore();
  const { lesson } = useLocalization();

  return <AddTopicEl onClick={() => startEditTopic(props.topicFactory())}>
    <SvgImage icon={KnownIcons.plus} />
    <TextNode>{lesson.new_topic}</TextNode>
  </AddTopicEl>;
}