import React, { CSSProperties, PropsWithChildren } from 'react';

export function ZeroSizeCenteredContainer(props: PropsWithChildren<{ alignItems?: string; style?: CSSProperties }>) {
  return <div style={{
    ...props.style,
    height: 0,
    width: '100%',
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    alignItems: props.alignItems || 'center',
    pointerEvents: 'none'
  }}>
    {props.children}
  </div>;
}