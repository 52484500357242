import styled from 'styled-components';


export const ThemeVariables = styled.div`
  --top-gap: 20px;
  --font-normal: ${p => p.theme.fonts.normal};
  --color-button: ${p => p.theme.currentTheme.button};
  --color-icon-main: ${p => p.theme.currentTheme.iconMain};
  --color-icon-light: ${p => p.theme.currentTheme.iconLight};
  --color-text-main: ${p => p.theme.currentTheme.textMain};
  --color-hover: ${p => p.theme.currentTheme.hover};
  --color-selected: ${p => p.theme.currentTheme.select};
  --color-bg: ${p => p.theme.currentTheme.background};
  --color-error1: ${p => p.theme.currentTheme.error1};
`;

export const StyledApp = styled(ThemeVariables)<{clr:string}>`

  --color-accent: ${p => p.clr};
  
  user-select: none;
  min-height: 100%;
  position: relative;
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb,
  *::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.currentTheme.iconMain};
    border-radius: 5px;
    outline: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;