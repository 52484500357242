import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { DiagonalLineIconModifier } from '../../../../../../../components/Icon/DiagonalLineIconModifier';
import { Stack } from '../../../../../../../components/low-level-components/Stack';
import React, { CSSProperties } from 'react';
import { RawSvgString } from '*.svg';
import { useThemeStore } from '../../../../../../../store/themeStore';
import theme from '../../../../../../../theme';

type RedCameraCardButtonParams = {
  showDisabledMedia: boolean;
  className?: string;
  inOn?: boolean;
  inLocalOn?: boolean;
  icon: RawSvgString;
  onClick?;
  style?: CSSProperties;
};

export function RedCameraCardButton(props: RedCameraCardButtonParams) {
  const redColor = theme.colors[useThemeStore().theme].error1;
  const disabledColor = theme.colors[useThemeStore().theme].iconLight;


  const color = props.showDisabledMedia && props.inOn ? disabledColor : redColor;
  const onClick = props.showDisabledMedia && props.inOn ? () => {} : props.onClick;

  return <DiagonalLineIconModifier
    style={props.style}
    color={color}
    className={props.className}
    isOn={props.inOn || props.inLocalOn}
    onClick={onClick}
  >
    <SvgImage
      filled
      color={color}
      icon={props.icon}
    />
  </DiagonalLineIconModifier>;

}