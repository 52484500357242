import React from 'react';
import { ZeroSizeCenteredContainer } from './ZeroSizeCenteredContainer';
import { LandingPageImages } from './LandingPageImages';
import { Theme, useThemeStore } from '../../store/themeStore';

export function LandingPageBgPart3() {
  const lightTheme = useThemeStore().theme === Theme.light;
  return <ZeroSizeCenteredContainer>
    <svg width="169" height="2783" viewBox="1500 0 169 2783"
         overflow="visible" style={{
      zIndex: -1,
      transform: `translate(0, 1120px)`
    }}>
      {lightTheme ? <Light/> : <Dark/>}
      <image href={LandingPageImages.photoPawnOnBoard}/>
    </svg>
  </ZeroSizeCenteredContainer>
}

function Dark() {
  return <>
    <path d="M1 291H3169V2783H1V291Z" fill="url(#paint31)"/>
    <path d="M3169 1563.5L3169 563L1.998 1605L1.99877 662L3169 1563.5Z" fill="#CD9D76" fillOpacity="0.4"/>
    <path d="M3.27617e-05 749.5L3.27617e-05 662.5L3168.5 1561.5L3168.5 -0.000138499L3.27617e-05 749.5Z" fill="#9B8570" fillOpacity="0.8"/>
    <defs>
      <linearGradient id="paint31" x1="1585" y1="291" x2="1585" y2="2136.19" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C2C2C"/>
        <stop offset="0.433862" stopColor="#EBD8C8"/>
        <stop offset="0.623862" stopColor="#EBD8C8"/>
        <stop offset="1" stopColor="#2C2C2C"/>
      </linearGradient>
    </defs>
  </>
}

function Light() {
  return <>
    <path d="M0 291H3168V2783H0V291Z" fill="url(#paint30)"/>
    <path d="M3168 1563.5L3168 563L0.998001 1605L0.998775 662L3168 1563.5Z" fill="#674F3B" fillOpacity="0.3"/>
    <path d="M3.27617e-05 749.5L3.27617e-05 662.5L3168.5 1561.5L3168.5 -0.000138499L3.27617e-05 749.5Z" fill="#9B8570" fillOpacity="0.8"/>
    <defs>
      <linearGradient id="paint30" x1="1584" y1="291" x2="1584" y2="2136.19" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="0.433862" stopColor="#2C2C2C"/>
        <stop offset="0.623862" stopColor="#2C2C2C"/>
        <stop offset="1" stopColor="white"/>
      </linearGradient>
    </defs>
  </>
}