import Input from '../low-level-components/Input';
import React from 'react';
import { useLocalization } from '../../l10n/UseLocalization';
import { validateEmail } from '../../logic/validateEmail';
import { JustText } from '../JustText/JustText';
import { InputAdornment } from '@material-ui/core';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';

export function EmailField(props: {
  email: string,
  notValid: boolean,
  setNotValid: (b: boolean) => void,
  onEnterPressed?: () => void,
  setEmail: (s: string) => void,
  errorText?: string
}) {
  const { auth } = useLocalization();

  return <div>

    <JustText style={{
      paddingBottom: 5,
      height: 18,
      color: 'var(--color-error1)',
    }}>{props.notValid ? auth.wrong_email : (props.errorText || '')}&nbsp;</JustText>

    <Input
      label={auth.email}
      fullWidth
      value={props.email}
      onChange={(e) => props.setEmail(e.target.value)}
      error={!props.email || props.notValid}
      onKeyUp={(e) => {
        let valid = validateEmail(props.email);
        props.setNotValid(!valid);
        props.onEnterPressed && valid && e.keyCode === 13 && props.onEnterPressed();
      }}

      endAdornment={props.email && (
        <InputAdornment
          position='start'
          onClick={() => props.setEmail('')}
        >
          <SvgImage style={{ marginRight: '.5rem' }} icon={KnownIcons.cross} withHover />
        </InputAdornment>
      )}
    />
  </div>;
}