import { ConfirmationPopupMenu } from '../../../../../components/PopupMenu/ConfirmationPopupMenu';
import { SvgImage } from '../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../components/Icon/KnownIcons';
import React from 'react';
import { useTasksStore } from '../../../../../store/tasks/TasksStore';
import { useLessonStore } from '../../../../../store/lesson/LessonStore';
import { TaskId } from '@chessclub/grpc_wrapper';
import { useLocalization } from '../../../../../l10n/UseLocalization';

export function ConfirmDeleteTaskButton({ taskId }: { taskId: TaskId }) {

  const {
    deleteQuestionById,
    deleteQuestions,
  } = useTasksStore();

  const {
    roomId,
    setCurrentMonitoringQuestionItem,
    monitoringStudentId,
    monitoringStudentDisplayingQuestionId,
    isStudentLayout,
    users,
  } = useLessonStore();

  const { lesson } = useLocalization();

  if (isStudentLayout)
    return null;

  function handleRemove() {
    if (monitoringStudentId) {
      if (taskId === monitoringStudentDisplayingQuestionId) {
        setCurrentMonitoringQuestionItem(null);
      }
      deleteQuestionById({
        qId: taskId,
        roomId,
        userId: monitoringStudentId,
      });
    } else {
      deleteQuestions([taskId]);
    }
  }

  return <ConfirmationPopupMenu
    text={lesson.delete + "?"}
    action={handleRemove}
  >
    <SvgImage icon={KnownIcons.trash} withHover />
  </ConfirmationPopupMenu>
}