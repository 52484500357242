import React from 'react';
import { useLocalization } from '../../l10n/UseLocalization';
import { StyledLink } from './StyledLink';

function VerticalBar() {
  return <span className='vertical-bar' style={{ margin: '0px 5px' }}>|</span>;
}

export function Links() {
  const { footer: lang } = useLocalization();
  return <>
    <StyledLink to={'/help'}>{lang.help}</StyledLink>
    {/*<VerticalBar/>*/}
    <StyledLink to={'/terms-of-service'}>{lang.termsOfService}</StyledLink>
    {/*<VerticalBar/>*/}
    <StyledLink to={'/support-us'}>{lang.supportUs}</StyledLink>
    {/*<VerticalBar/>*/}
    <StyledLink to={'/contacts'}>{lang.contacts}</StyledLink>
  </>;
}