import React, { PropsWithChildren } from 'react';
import { useLocalization } from '../../l10n/UseLocalization';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { TextLink } from '../../components/low-level-components/TextLink';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';
import { Grid } from '@material-ui/core';

export function EnterLessonPageTemplate(props: PropsWithChildren<{}>) {

  const { auth } = useLocalization();

  return <PageTemplate header={true} alterAuthWidget={
    <>
      <TextLink linkText={auth.sign_up} to={'/auth/register/' + document.location.pathname.split('/').pop()} />
      <div style={{ margin: '0 10px' }}></div>
      <TextLink linkText={auth.login} to={'/auth/login/' + document.location.pathname.split('/').pop()} />
    </>

  }>
    <LoginPageContainer logo={false}>
      <Grid container justifyContent='center'>
        {props.children}
      </Grid>
    </LoginPageContainer>
  </PageTemplate>;
}