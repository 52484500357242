import { IProfile } from '@chessclub/grpc_wrapper';

export type IUserProfile =  {
  profile: IProfile;
  onChange;
}

export enum EnumSettingsTab {
  personalInfo = 'personalInfo',
  decoration = 'decoration',
  security = 'security',
}

export type SettingsTab =
  EnumSettingsTab.personalInfo |
  EnumSettingsTab.decoration |
  EnumSettingsTab.security ;

