import React from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LandingPageStyledComponents } from './LadingPageStyledComponents';
import { useLocalization } from '../../l10n/UseLocalization';
import { Space } from '../../components/low-level-components/Space';
import { ImagesSlider } from './ImagesSlider';
import { Theme, useThemeStore } from '../../store/themeStore';
import { LandingPageNavigation, LandingPageNavigationIndex } from './LandingPageNavigation';
import { LandingPageBgPart2 } from './LandingPageBgPart2';
import { LandingPageBgPart1 } from './LandingPageBgPart1';
import { LandingPageBgPart3 } from './LandingPageBgPart3';
import { LandingPageBgPart4 } from './LandingPageBgPart4';
import { LandingGirlPhoto } from './LandingGirlPhoto';

const {
  Section, Title, MainSubTitle, MainTitle, CardCapabilities, CardOurPlatform, CardAdvantages, CardExpert, CardList,
} = LandingPageStyledComponents;

export function LandingPage() {

  const { landing } = useLocalization();
  const { theme } = useThemeStore();

  let lightTheme = theme === Theme.light;

  return <PageTemplate alterNavigation={<LandingPageNavigation />}>

    <div style={{ maxWidth: 980, margin: '0 auto' }}>

      {/* картинка, не занимает место по вертикали */}
      <LandingPageBgPart1 />

      {/* обучение на новом уровне */}
      <div>
        <Space size={50} />
        <MainTitle text={landing.mainTitle} />
        <MainSubTitle text={landing.mainSubTitle} />
      </div>

      <Space size={800} />

      {/* первая секция с треугольниками, не занимает место по вертикали */}
      <LandingPageBgPart2 />

      {/* о платформе */}
      <Section className={LandingPageNavigationIndex.about}>
        <div style={{ display: 'grid', gap: 50, gridTemplateColumns: '500px 1fr' }}>
          <div>
            <Title text={landing.aboutUs} style={{ color: lightTheme ? 'white' : 'black' }} />
            <MainSubTitle text={landing.aboutUsText} style={{ color: lightTheme ? 'white' : 'black' }} />
          </div>
          <LandingGirlPhoto />
        </div>
      </Section>

      <Space size={100} />

      {/* наши возможности */}
      <Section>
        <Title text={landing.ourCapabilitiesTitle} style={{ color: lightTheme ? 'white' : 'black' }} />
        <CardList justify={'center'} elements={landing.ourCapabilitiesElements}
                  card={CardCapabilities} />
      </Section>

      <Space size={300} />

      {/* наша платформа для вас если вы */}
      <Section>
        <Title text={landing.ourPlatformTitle}
               style={{ width: '100%', textAlign: 'right', color: 'var(--color-text-main)' }} />
        <CardList justify={'center'} elements={landing.ourPlatformElements.map(el => {
          return [el[lightTheme ? 0 : 1], el[2]];
        })} card={CardOurPlatform} style={{ paddingLeft: 190, transform: 'translate(0px,0px)' }} />
      </Section>

      <Space size={100} />

      <LandingPageBgPart3 />

      {/* наши приемущества */}
      <Section className={LandingPageNavigationIndex.advantages}>
        <Title text={landing.ourAdvantagesTitle} style={{ color: lightTheme ? 'var(--color-text-main)' : '#ffffffcc' }} />
        <CardList justify={'left'} elements={landing.ourAdvantagesElements}
                  card={CardAdvantages} />
      </Section>

      <Space size={300} />

      <ImagesSlider slides={landing.imagesSlider} />

      <Space size={300} />

      <LandingPageBgPart4 />

      {/* наши эесперты */}
      <Section className={LandingPageNavigationIndex.experts}>
        <Title text={landing.ourExpertsTitle} style={{ color: lightTheme ? 'var(--color-text-main)' : '#ffffffcc' }} />
        <CardList justify={'center'} elements={landing.ourExpertsElements} card={CardExpert} />
      </Section>

      <Space size={100} />

    </div>
  </PageTemplate>;
}

