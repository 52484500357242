import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Logo, LogoSize } from '../Icon/Logo';
import { Space } from '../low-level-components/Space';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 375px;
  min-width: 375px;
  margin: 0 auto;
`;

export function LoginPageContainer (props:PropsWithChildren<{logo?: boolean}>) {
  return <StyledContainer>
    {props.logo !== false ? <Logo size={LogoSize.large} /> : <Space size={170}></Space>}
    {props.children}
  </StyledContainer>
}