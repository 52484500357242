export function validateEmail(str) {
  if (!str || str.length === 0) return false; // "Empty string or nil"

  const lastAt = str.lastIndexOf('@');
  if (lastAt < 1 || lastAt >= 65) return false; // "Invalid @ symbol usage"

  const localPart = str.substring(0, lastAt);
  const domainPart = str.substring(lastAt + 1);

  // Validate local part
  if (localPart.length > 64 || localPart.includes('..') || (localPart.match(/@/g) || []).length > 1) {
    return false; // "Local name is invalid"
  }
  if (localPart.indexOf('"') > 0) return false; // "Invalid usage of quotes"

  // Validate domain part
  if (domainPart.length === 0 || domainPart.length > 253 || !domainPart.includes('.') || domainPart.startsWith('.') || domainPart.includes('..')) {
    return false; // "Domain is invalid"
  }

  // General pattern match
  const emailPattern = /^[\w!#$%&'*+/=?^_`{|}~.-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(str); // Return true if pattern matches
}






