import React from 'react';
import { LargeText } from '../../components';
import { Grid } from '@material-ui/core';
import { TextLink } from '../../components/low-level-components/TextLink';
import { useLocalization } from '../../l10n/UseLocalization';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';

export function NotFoundPage() {
    const { auth } = useLocalization();

    return <PageTemplate header={true}>
        <div style={{
            width: '100%',
            textAlign: 'center',
            paddingTop: 200,
        }}>
            <Grid container justifyContent='center'>
                <LargeText text={auth.page_not_found}/>
            </Grid>
        </div>
        <div style={{
            width: '100%',
            textAlign: 'center',
            paddingTop: 30,
        }}>
            <TextLink linkText={auth.home_page} to={'/'} />
        </div>
    </PageTemplate>
}