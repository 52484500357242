import React, { CSSProperties } from 'react';
import { useTheme } from 'styled-components';

const style : CSSProperties = {
  textAlign: 'center',
  margin: '100px auto',
  width: 500,
  font: 'var(--font-normal)',
  color: 'var(--color-icon-main)',
  fontSize: 40,
};

export function LargeText({text}:{text:string}) {
  const {currentTheme} = useTheme()
  return <div style={{...style, color: currentTheme.textMain}}>
    {text}
  </div>;
}