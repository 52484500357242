import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Button } from '../../../../../components/low-level-components/Button';
import { ChatMessageView } from './ChatMessage';
import { HidingScrollbar } from '../../../../../components/low-level-components/HidingScrollbar';
import { MessageMenu } from './ChatMessage/MessageMenu';
import { IChatMessage } from '../../../../../interfaces/chat-message';
import { MessageRelate } from './ChatMessage/MessageRelate';
import { getDayAndMonth } from '../../../../../helpers/time.helpers';
import { useThemeStore } from '../../../../../store/themeStore';
import { useLessonStore } from '../../../../../store/lesson/LessonStore';
import { useChatStore } from '../../../../../store/chatStore';
import { useAuthStore } from '../../../../../store/auth/AuthStore';
import { IMessage } from '@chessclub/grpc_wrapper';
import { SvgImage } from '../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../components/Icon/KnownIcons';

const StyledContainer = styled.div`
  padding-top: -10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: 10px;
  p {
    padding: 0;
    margin: 0;
  }
  img {
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
  }
`;

const StyledMessagesWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  margin-top: auto;
  width: 100%;
  font: ${({ theme }) => theme.fonts.normal};
  background-color:${({ theme }) => theme.currentTheme.hover}; 
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border-radius: 20px;
  padding: 5px 5px;
  box-sizing: border-box;
`;

const ChatMessageContainer = styled.div`
  flex-grow: 0;
  padding: 0 10px;
`;

const InputButtons = styled.div`
  display: grid;
  grid-template-columns: 9fr 3fr;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
`;

const stripTagsRegex = /(&nbsp;|<([^>]+)>)/ig

export function Chat() {
  const inputRef = useRef<HTMLDivElement>();
  const scrollRef = useRef<HTMLDivElement>();
  const editor = null;
  const [text, setText] = useState('');
  const { theme } = useThemeStore();

  const {
    roomId,
  } = useLessonStore();

  const {
    messages: chatMessages,
    createChatMessage,
    deleteChatMessage,
    updateChatMessage,
  } = useChatStore();

  const { user } = useAuthStore();

  const [messageRelateState, setRelateState] = useState({
    id: null,
    type: '',
    header: '',
    text: '',
  });

  const [menuEdition, setMenuEdition] = useState({
    isHidden: true,
    me: false,
    pos: { x: 0, y: 0 },
  });

  // const addProfile = async (userId: string) => {
  //   const chatUsers = JSON.parse(localStorage.getItem('chatUsers'));
  //   const profile = await ServerApi.accessService.getProfile(userId);
  //   const user = { id: userId, name: profile.firstName + ' ' + profile.lastName };
  //   localStorage.setItem('chatUsers',
  //     JSON.stringify(chatUsers?.length > 0 ? [...chatUsers, user] : [user])
  //   )
  // };

  // const createMessage = async (msg: FullChatMessage) => {
  //   if (rightTab !== LessonRightTab.CHAT) {
  //     addUnreadMessage(msg);
  //   }
  //   addMessage(msg);
  //
  //   if (msg.userId === roomUserId || msg.userId === logged?.id) {
  //     scrollToBottom();
  //   }
  //
  //   if (!localStorage.getItem('chatUsers')) {
  //     await addProfile(msg.userId);
  //   } else {
  //     const chatUsers = JSON.parse(localStorage.getItem('chatUsers'));
  //     if (chatUsers.find((u) => u.id === msg.userId)) return;
  //     await addProfile(msg.userId);
  //   }
  // };

  function hideMenu() {
    setMenuEdition((prev) => ({ ...prev, isHidden: true }));
  }

  function showContextMenu(event: React.SyntheticEvent & { nativeEvent: MouseEvent }, messageData: IChatMessage) {
    event.preventDefault();
    const x = event.nativeEvent.pageX;
    const y = event.nativeEvent.pageY;
    setMenuEdition((prev) => ({
      ...prev,
      me: messageData.userId === user?.id,
      pos: { x, y },
      isHidden: false,
      ...messageData,
    }));
  }

  // const temporaryMessage = {
  //   author: logged?.name || logged?.email || users?.find((u) => u.id === userId)?.name,
  //   avatarColor: '#EF9033',
  //   userId: userId,
  //   roomId: roomId,
  //   text: editor && editor.getHTML(),
  // };

  // function scrollToBottom() {
  //   messageConteinerRef?.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  // }


  // const getAuthor = (userId: string) => {
  //   const users = JSON.parse(localStorage.getItem('chatUsers'));
  //   if (!users || users?.length === 0 || !users.find((u) => u.id === userId)) {
  //     addProfile(userId);
  //     return JSON.parse(localStorage.getItem('chatUsers'))?.find((u) => u.id === userId)?.name;
  //   }
  //   return users.find((u) => u.id === userId)?.name;
  // };

  // const getRelateMsg = (responseId: string | undefined) => {
  //   const msg = responseId ? chatMessages.find((m) => m.id === responseId) : undefined;
  //   if (!msg) return;
  //   return { ...msg, author: getAuthor(msg.userId) };
  // };


  // console.log({ chatMessages });

  const label = messageRelateState.type ? (
    <MessageRelate headerText={messageRelateState.header} messageText={messageRelateState.text} />
  ) : null;

  let send = () => {
    let txt = text;
    txt = txt.replaceAll("<div><br><div>", "\n")
    txt = txt.replaceAll("<div>", "\n")
    txt = txt.replaceAll("</div>", "\n");
    txt = txt.replaceAll("\n\n", "\n");
    txt = btoa(unescape(encodeURIComponent(txt)));

    if (messageRelateState.type === 'edit') {
      const msgForUpdate = chatMessages.find((msg) => msg.id === messageRelateState.id);
      updateChatMessage({ ...msgForUpdate, text: txt });
      updateChatMessage({ ...msgForUpdate, text: txt });
      return
    }

    const msg : IMessage = {
      roomId,
      userId: user?.id,
      epoch: new Date().getTime(),
      text: txt,
    };

    if (messageRelateState.type === 'reply')
      msg.responseId = messageRelateState.id

    createChatMessage(msg);

    setRelateState({ id: '', type: '', header: '', text: '' });
    setText('')
    inputRef.current.innerHTML = ""
  };


  useEffect(() => {
    scrollRef.current.scrollTo({top: scrollRef.current.scrollHeight});
  }, [chatMessages])

  function getWithDate(el: IMessage) {
    return !(chatMessages[chatMessages.findIndex((e) => e.id === el.id) - 1] &&
      getDayAndMonth(chatMessages[chatMessages.findIndex((e) => e.id === el.id) - 1]?.epoch * 1000) ===
      getDayAndMonth(el.epoch * 1000));
  }

  let onDrop = (e) => {
    const data = e.dataTransfer.getData('text/plain');
    let text = "FEN: " + JSON.parse(data).fen;
    setTimeout(() => {
       (e.target as any).innerHTML = text;
        setText(text)
    });

  };

  return (
    <StyledContainer className="Chat_tsx">
        <HidingScrollbar
          elementRef={scrollRef}
          // onIconClick={scrollToBottom}
          style={{height: '100%' }}
          iconStyle={{
            bottom: '3px',
            transform: 'rotate(90deg)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ChatMessageContainer>
            {chatMessages.map((el, key) => {
              return (

                <ChatMessageView
                  withDate={getWithDate(el)}
                  theme={theme}
                  key={key}
                  {...el}
                  author={el.userId}
                  relate={el.responseId}
                  showMenu={showContextMenu}
                  authUserId={user?.id}
                />
              );
            })}
          </ChatMessageContainer>
        </HidingScrollbar>
      <InputContainer>
        <div contentEditable='true' onInput={e => setText((e.target as any).innerHTML)} onDrop={onDrop}
             style={{color: "var(--color-icon-main)",padding:5,outline: "none", overflowX:'scroll'}} ref={inputRef}/>
        <div style={{display:'flex', flexDirection:"column-reverse"}}>
          <SvgImage onClick={send} icon={KnownIcons.arrow_right} active r={32} withHover/>
        </div>
      </InputContainer>
      <MessageMenu
        {...menuEdition}
        hide={hideMenu}
        deleteMessage={deleteChatMessage}
        setReplyMessage={setRelateState}
        setEditMessage={setRelateState}
        setEditorContent={editor?.commands.setContent}
      />
    </StyledContainer>
  );
}

