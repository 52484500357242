import React from 'react';
import { InterfaceClr, Lang, useUiStore } from '../../store/uiStore';
import { PopupMenuItem } from '../PopupMenu/PopupMenuItem';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import styled from 'styled-components';
import { StyledLinkWrapper } from './StyledLink';

const langs = {
  [Lang.ru]: "Русский",
  [Lang.en]: "English",
  [Lang.cn]: "中文",
  [Lang.hi]: "हिन्दी",
}

export function LangSwitch() {
  return <PopupMenu popupContent={LangSelectPopupContent}>
    <LangButton />
  </PopupMenu>
}

const LangBtnStyled = styled.div<{color: InterfaceClr}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.theme.currentTheme.hover};
  }
`

function LangButton() {
  const { lang, interfaceClr } = useUiStore();
  return <StyledLinkWrapper>
    {langs[lang]}
  </StyledLinkWrapper>
}

function LangSelectPopupContent() {
  const { setLang, lang } = useUiStore();
  return <>
    {Object.entries(langs).map((entry, i) => {
      return <PopupMenuItem
        height={38}
        active={lang === entry[0]}
        key={i}
        text={entry[1]}
        onClick={() => setLang(entry[0] as Lang)}
      />
    })}
  </>
}