import {GRPC, ChessclubApi, apiContext} from '@chessclub/grpc_wrapper';
import { loggedUser } from '../store/auth/AuthStore';
import { grpcUrl } from '../config';

export const ServerApi: ChessclubApi = new GRPC(grpcUrl);

apiContext.sessionExpiredCallback = () => {
  loggedUser.clear()
  window.location.href = "/auth/login";
};
