import React from 'react';
import styled from 'styled-components';
import { ChessboardSvg } from '../../../../components/Chessboard/ChessboardSvg';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { QuestionBase } from '@chessclub/grpc_wrapper';
import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';
import { useLocalization } from '../../../../l10n/UseLocalization';

export function MovesTabCurrentQuestion() {

  const { mouseOverMove } = useLessonUiStore();
  const { taskToDisplay } = useTaskToDisplay();
  const { currentTaskGameState, currentTaskGameApi } = useTasksStore();
  const { startEditTask } = useEditingTaskStore();
  const { lesson } = useLocalization();

  if (!taskToDisplay)
    return null;

  const fen = mouseOverMove?.fen || currentTaskGameState?.fen || taskToDisplay.fen;

  const markers = currentTaskGameState?.markers && (mouseOverMove ?
    currentTaskGameState?.markers[mouseOverMove.index] : currentTaskGameState?.markers[-1]);

  const activateStartPosition = () => {
    currentTaskGameApi.setStepIndex(-1);
    currentTaskGameApi.requestUpdate();
  };
  const editTask = () => startEditTask(taskToDisplay);

  return <div>
    <div onClick={activateStartPosition}>
      <ChessboardSvg fen={fen} markers={markers} />
    </div>
    <div style={{display:'flex', padding: 6}}>
      <SvgImage tooltip={lesson.edit} icon={KnownIcons.pencil} withHover onClick={editTask} />
      <SvgImage tooltip={lesson.reset} icon={KnownIcons.reverse} withHover />
      <SvgImage tooltip={lesson.save} icon={KnownIcons.diskette} withHover />
    </div>
  </div>;
}