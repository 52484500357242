import React from 'react';
import { ZeroSizeCenteredContainer } from './ZeroSizeCenteredContainer';
import { LandingPageImages } from './LandingPageImages';
import { Theme, useThemeStore } from '../../store/themeStore';

export function LandingPageBgPart2() {

  const lightTheme = useThemeStore().theme === Theme.light;
  const img = lightTheme ? LandingPageImages.handFiguresWhite : LandingPageImages.handFiguresBlack;

  return <ZeroSizeCenteredContainer>
    <svg width="175" height="2225" viewBox="1500 0 175 2225"
      overflow="visible" style={{
        zIndex: -2,
        transform: `translate(0, 750px)`
    }}>
      {lightTheme ? <Light/> : <Dark/>}
      <image href={img} y={770.5}/>
    </svg>
  </ZeroSizeCenteredContainer>
}

function Dark() {
  return <>
    <path d="M0 149H3170V2225H0V149Z" fill="url(#paint21)"/>
    <path d="M3167.99 771L-0.00397713 1621.5L-0.00390625 -0.000138478L3167.99 890.5L3167.99 771Z" fill="#F0E2D6"/>
    <path d="M-0.000936676 960.5L3169.5 1872L3168 149.5L-0.000936676 1062L-0.000936676 960.5Z" fill="#CD9D76" fillOpacity="0.3"/>
    <defs>
      <linearGradient id="paint21" x1="1585" y1="149" x2="1585.26" y2="1338.23" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C2C2C"/>
        <stop offset="0.496678" stopColor="#EBD8C8"/>
        <stop offset="1" stopColor="#2C2C2C"/>
      </linearGradient>
    </defs>
  </>
}

function Light() {
  return <>
    <path d="M5 149H3175V2225H5V149Z" fill="url(#paint20)"/>
    <path d="M3167.99 771L-0.002024 1621.5L-0.00195312 0.000105663L3167.99 890.5L3167.99 771Z" fill="#674F3B" fillOpacity="0.4"/>
    <path d="M-0.000936676 960.5L3169.5 1872L3168 149.5L-0.000936676 1062L-0.000936676 960.5Z" fill="#CD9D76" fillOpacity="0.3"/>
    <defs>
      <linearGradient id="paint20" x1="1590" y1="149" x2="1588.8" y2="1707.03" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="0.5" stopColor="#2C2C2C"/>
        <stop offset="1" stopColor="white"/>
      </linearGradient>
    </defs>
  </>
}