import React, { useMemo } from 'react';
import { TextNode } from '../../components/low-level-components/TextNode';
import { ChessboardSvg } from '../../components/Chessboard/ChessboardSvg';
import { randomFen } from '../../logic/randomFen';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { RoomSettings } from '@chessclub/grpc_wrapper/src/api/types/IRoom';
import styled from 'styled-components';
import { useEditingRoomStore } from '../../store/editing-room/EditingRoomStore';
import { useLocalization } from '../../l10n/UseLocalization';

export const Svg = styled.svg`
  
  & .interactive rect {
    stroke: #888;
    fill: transparent;
    cursor: pointer;
    stroke-width: 1.4;
    stroke-dasharray: 3 3;
  }

  & .interactive rect.active {
    stroke: var(--color-accent);
    stroke-dasharray: 9999;
  }
`

function Toggle(props: React.SVGProps<SVGRectElement> & {settings: RoomSettings; property: keyof RoomSettings}) {

  const {updateEditingRoom} = useEditingRoomStore();

  function toggle() {
    const settings = {...props.settings, [props.property] : !props.settings[props.property]};
    updateEditingRoom({ settings });
  }

  return <rect
    {...props}
    rx={5}
    height={20}
    onClick={toggle}
    className={props.settings[props.property] ? "active" : ""}
  />
}

export function StudentViewSettings(props: {settings: RoomSettings}) {
  const { rooms } = useLocalization();
  const fen = useMemo(randomFen, [])
  return <div>
    <TextNode style={{padding: 15}}>{rooms.external_view}</TextNode>
    <Svg width={600} height={350}>
      <defs>
        <filter id="blur">
          <feGaussianBlur stdDeviation="3" />
        </filter>
      </defs>
      <g transform={"scale(0.55) translate(246,187)"} filter="url(#blur)"  >
        <ChessboardSvg fen={fen} />
      </g>
      <g fill={'none'}  >
        <rect x={5} y={5} width={590} height={340} rx={12}  stroke={"#ccc"}/>

        <g transform={"translate(55,68) scale(0.07) "}>
          <svg dangerouslySetInnerHTML={{__html: KnownIcons.question_in_rect}} />
        </g>
        <g transform={"translate(125,68) scale(0.07) "}>
          <svg dangerouslySetInnerHTML={{__html: KnownIcons.stopwatch}} />
        </g>
        <g transform={"translate(435,68) scale(0.07) "}>
          <svg dangerouslySetInnerHTML={{__html: KnownIcons.camera}} />
        </g>
        <g transform={"translate(505,68) scale(0.07) "}>
          <svg dangerouslySetInnerHTML={{__html: KnownIcons.chat}} />
        </g>

        <rect x={50} y={100} height={200} width={120} stroke={"#ccc"} />
        <rect x={430} y={100} height={200} width={120}  stroke={"#ccc"}  />

        <g className={'interactive'}>
          <Toggle settings={props.settings} property={"tasks"}
                  x={50} y={70} width={50} />

          <Toggle settings={props.settings} property={"moves"}
                  x={120} y={70} width={50} />

          <Toggle settings={props.settings} property={"cameras"}
                  x={430} y={70} width={50} />

          <Toggle settings={props.settings} property={"chat"}
                  x={500} y={70} width={50} />

          <Toggle settings={props.settings} property={"toolbar"}
                  x={200} y={70} height={20} width={200} />

          {/*<Toggle settings={props.settings} property={"toolbar"}*/}
          {/*        x={200} y={280} width={200} />*/}

          <Toggle settings={props.settings} property={"footer"}
                  x={50} y={310} width={500}/>
        </g>
      </g>
    </Svg>
  </div>
}