import styled from 'styled-components';

const StyledHr = styled.hr<{ color: string }>`
  margin: 0px 0px 10px 0px;
  border: 1px solid ${({ color }) => color};
  border-top: none;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  * {
    color: ${({ theme }) => theme.currentTheme.iconMain};
    font: normal 400 15px/15px Roboto;
  }
`;



const StyledLinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  &  svg {
    margin-right: ${({ theme }) => theme.spacing.small}px;
  }
  &  svg:hover {
    cursor: pointer;
  }

  :nth-child(3) {
    margin-left: auto;
    margin-right: auto;
    margin-left: -0.7rem;
  }

  :nth-child(2) {
    margin-right: auto;
  }

  //@media (max-width: 943px) {
  //  :nth-child(3) {
  //    order: 3;
  //  }
  //}
  //
  //@media (max-width: 31.938rem) {
  //  :nth-child(3) {
  //    flex-direction: column;
  //    align-items: flex-start;
  //
  //    .vertical-bar {
  //      display: none;
  //    }
  //  }
  //}
  //
  //@media (max-width: 26rem) {
  //  :nth-child(3) {
  //    width: 100%;
  //  }
  //
  //  :nth-child(1) {
  //    margin-right: auto;
  //  }
  //
  //  :nth-child(2) {
  //    order: 4;
  //    margin-top: 11px;
  //
  //    & > div {
  //      margin-left: -0.5rem;
  //    }
  //  }
  //}
`;


const StyledFooter = styled.footer`
  position: static;
  padding-bottom: 10px;
  width: 100% !important;
  max-width: none !important;
  align-self: flex-end;
  // background-image: linear-gradient(0deg, transparent 10%, #8881);
`;


export const PageFooterStyledComponents = {
  StyledFooter,
  StyledHr,
  StyledLinks,
  StyledRow,
}