import React from 'react';
import { ZeroSizeCenteredContainer } from './ZeroSizeCenteredContainer';
import { LandingPageImages } from './LandingPageImages';
import { Theme, useThemeStore } from '../../store/themeStore';

export function LandingPageBgPart4() {
  const lightTheme = useThemeStore().theme === Theme.light;
  return <ZeroSizeCenteredContainer>
    <svg width="168" height="2295" viewBox="1500 0 168 2295"  overflow="visible" style={{
      zIndex: -1,
      transform: `translate(0, 520px)`
    }}>
      {lightTheme ? <Light/> : <Dark/>}
    </svg>
  </ZeroSizeCenteredContainer>
}

function Light() {
  return <>
      <path d="M-586 1165L3168 2241L3168 88.9998L-586 1165Z" fill="#674F3B" fillOpacity="0.3"/>
      <path d="M3869 1133.53L-0.000100318 2295L0 -0.000169119L3869 1133.53Z" fill="#CD9D76" fillOpacity="0.3"/>
  </>
}

function Dark() {
  return <>
      <path d="M-586 1165L3168 2241L3168 88.9998L-586 1165Z" fill="#CD9D76" fillOpacity="0.4"/>
      <path d="M3869 1133.53L-0.000100318 2295L0 -0.000169119L3869 1133.53Z" fill="#674F3B" fillOpacity="0.3"/>
  </>
}