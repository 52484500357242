import React from 'react';
import { Box } from '@material-ui/core';
import { Logo, LogoSize } from '../Icon/Logo';

import { PageHeaderStyledComponents } from './PageHeaderStyledComponents';
import { UserProfileWidget } from './UserProfileWidget';
import { NavLinks } from './NavLinks';
import { StudentsIcons } from './StudentssIcons/StudentsIcons';

const {
  StyledGridContainer,
  StyledNav,
} = PageHeaderStyledComponents;

type PageHeaderParams = {
  logo: LogoSize;
  alterNavigation?;
  alterAuthWidget?;
}

export function PageHeader(props:PageHeaderParams) {
  return (
    <StyledGridContainer
      // style={{background: "linear-gradient(to bottom, #111, #fff0)"}}
    >
      <div style={{width: "100%", height: 70, overflow: 'visible', position: 'fixed', top: 0, left: 0,
        background: "linear-gradient(180deg,  var(--color-bg) 95%,  transparent 100%)"
      }}>

      </div>
      <div
        className='nav-wrapper'
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', zIndex: 1 }}
      >
        <StyledNav>
          <Logo size={props.logo} />
          <Box
            display={'flex'}
            style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: 0 }}
          >
            <NavLinks alterNavigation={props.alterNavigation}/>
            <StudentsIcons />
            {props.alterAuthWidget || <UserProfileWidget />}
          </Box>
        </StyledNav>
      </div>
    </StyledGridContainer>
  );
}


