import { Link } from './Link';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.p`
  text-align: center;

  a:hover {
    text-decoration: underline;
  }
`;

export function TextLink(props: { linkText, to }) {
  let { linkText, to } = props;
  return (
    <Styled>
      <Link to={to}>{linkText}</Link>
    </Styled>
  );
}

